/** @jsxImportSource theme-ui */
import moment from 'moment';
import React, { useState } from 'react';
import { Box, Flex, Label, Input, Select, Button, Paragraph, Checkbox } from 'theme-ui';
import { ucase } from '../../utils/string-utils';

export const defaultTask = () => ({
    startDate: '',
    repeatPattern: 'none',
    repeatDetails: {},
    dates: {
        internalDueDate: { type: 'relative', relation: 'after', offset: '', unit: 'days', daysOffset: '' },
        relevantPeriodEnd: { type: 'relative', relation: 'after', offset: '', unit: 'months', daysOffset: '' },
        statutoryDueDate: { type: 'relative', relation: 'before', offset: '', unit: 'weeks', daysOffset: '' },
        expectedDateBilled: { type: 'relative', relation: 'before', offset: '', unit: 'weeks', daysOffset: '' },
    },
});

const generateTaskExplanationLabel = (task) => {
    if (task.repeatPattern === 'none' && moment(task.startDate).isValid()) {
        return 'The task will be created on the ' + moment(task.startDate).format('Do MMMM YYYY');
    }
    if (task.repeatPattern === 'weekly' && moment(task.startDate).isValid()) {
        return (
            'The task will first be created on the ' +
            moment(task.startDate).format('Do MMMM YYYY') +
            ' and then every ' +
            (task.repeatDetails.every || '?') +
            ' week(s) on a ' +
            (task.repeatDetails.dayOfWeek
                ? moment().day(parseInt(task.repeatDetails.dayOfWeek, 10)).format('dddd')
                : '?')
        );
    }
    if (task.repeatPattern === 'monthly' && moment(task.startDate).isValid()) {
        if (task.repeatDetails.monthlyOn === 'day') {
            return (
                'The task will first be created on the ' +
                moment(task.startDate).format('Do MMMM YYYY') +
                ' and then every ' +
                (task.repeatDetails.every || '?') +
                ' month(s) on the ' +
                (task.repeatDetails.dayOfMonth
                    ? moment()
                          .day(parseInt(task.repeatDetails.dayOfMonth, 10) - 2)
                          .format('Do')
                    : '?')
            );
        }
        if (task.repeatDetails.monthlyOn === 'last_working_day') {
            return (
                'The task will first be created on the ' +
                moment(task.startDate).format('Do MMMM YYYY') +
                ' and then every ' +
                (task.repeatDetails.every || '?') +
                ' month(s) on the last working day of the month'
            );
        }
        if (task.repeatDetails.monthlyOn === 'last_day') {
            return (
                'The task will first be created on the ' +
                moment(task.startDate).format('Do MMMM YYYY') +
                ' and then every ' +
                (task.repeatDetails.every || '?') +
                ' month(s) on the last day of the month'
            );
        }
        if (['first', 'second', 'third', 'fourth', 'last'].includes(task.repeatDetails.monthlyOn)) {
            return (
                'The task will first be created on the ' +
                moment(task.startDate).format('Do MMMM YYYY') +
                ' and then every ' +
                (task.repeatDetails.every || '?') +
                ' month(s) on the ' +
                task.repeatDetails.monthlyOn +
                ' ' +
                (task.repeatDetails.monthlyOnDayOfWeek === 'select'
                    ? '?'
                    : ucase(moment().day(parseInt(task.repeatDetails.monthlyOnDayOfWeek, 10)).format('dddd')))
            );
        }
        return (
            'The task will first be created on the ' +
            moment(task.startDate).format('Do MMMM YYYY') +
            ' and then every ' +
            (task.repeatDetails.every || '?') +
            ' month(s) on a ' +
            (task.repeatDetails.dayOfWeek
                ? moment().day(parseInt(task.repeatDetails.dayOfWeek, 10)).format('dddd')
                : '?')
        );
    }

    if (task.repeatPattern === 'yearly' && moment(task.startDate).isValid()) {
        if (task.repeatDetails.dayOfMonth === 'last_day_month') {
            return (
                'The task will first be created on the ' +
                moment(task.startDate).format('Do MMMM YYYY') +
                ' and then every year on the last day of ' +
                (task.repeatDetails.month
                    ? moment()
                          .month(parseInt(task.repeatDetails.month, 10) - 1)
                          .format('MMMM')
                    : '?')
            );
        }
        if (task.repeatDetails.dayOfMonth === 'last_working_day') {
            return (
                'The task will first be created on the ' +
                moment(task.startDate).format('Do MMMM YYYY') +
                ' and then every year on the last working day of ' +
                (task.repeatDetails.month
                    ? moment()
                          .month(parseInt(task.repeatDetails.month, 10) - 1)
                          .format('MMMM')
                    : '?')
            );
        }
        return (
            'The task will first be created on the ' +
            moment(task.startDate).format('Do MMMM YYYY') +
            ' and then every ' +
            (task.repeatDetails.dayOfMonth
                ? moment()
                      .day(parseInt(task.repeatDetails.dayOfMonth, 10) - 2)
                      .format('Do')
                : '?') +
            ' of ' +
            (task.repeatDetails.month
                ? moment()
                      .month(parseInt(task.repeatDetails.month, 10) - 1)
                      .format('MMMM')
                : '?')
        );
    }

    return '';
};

const TaskConfigForm = ({ tasks, setTasks }) => {
    const daysOfMonthOptions = Array.from({ length: 28 }, (_, i) => ({
        value: i + 1,
        label: `${moment(`${i + 1}-01-2020`, 'DD-MM-YYYY').format('Do')}`,
    }))
        .concat({ value: 'last_day_month', label: 'Last Day of Month' })
        .concat({ value: 'last_working_day', label: 'Last Working Day of Month' });

    const monthsOffsetOptions = Array.from({ length: 13 }, (_, i) => ({ value: `${i}`, label: `${i}` }));

    const daysOffsetOptions = [
        ...Array.from({ length: 31 }, (_, i) => ({
            value: i,
            label: i,
        }))
            .concat({ value: 'last_day_month', label: 'Last Day of Month' })
            .concat({ value: 'last_working_day', label: 'Last Working Day of Month' }),
        { value: 'first_day_month', label: 'First Day of Month' },
        { value: 'first_working_day', label: 'First Working Day of Month' },
    ];

    const updateTask = (index, field, value) => {
        const updatedTasks = [...tasks];
        updatedTasks[index] = { ...updatedTasks[index], [field]: value };
        setTasks(updatedTasks);
    };

    const updateDateField = (taskIndex, dateField, key, value) => {
        const updatedTasks = [...tasks];
        updatedTasks[taskIndex].dates[dateField] = {
            ...updatedTasks[taskIndex].dates[dateField],
            [key]: value,
        };
        setTasks(updatedTasks);
    };

    const addTask = () => {
        setTasks([...tasks, defaultTask()]);
    };

    const removeTask = (index) => {
        setTasks(tasks.filter((_, i) => i !== index));
    };

    return (
        <Box sx={{ borderRadius: '8px' }}>
            <Flex sx={{ alignItems: 'center' }}>
                <h3 style={{ fontWeight: 300 }}>Setup when to create the task</h3>
                {tasks.length ? (
                    <Button type="button" onClick={addTask} sx={{ backgroundColor: '#007bff', color: 'white', ml: 20 }}>
                        <i style={{ marginRight: '7px' }} className="fal fa-plus-circle" />
                        Schedule
                    </Button>
                ) : null}
            </Flex>
            {tasks.map((task, index) => {
                let pluralLabel = '';
                if (task.repeatPattern === 'daily') {
                    pluralLabel = 'day(s)';
                }
                if (task.repeatPattern === 'weekly') {
                    pluralLabel = 'week(s)';
                }
                if (task.repeatPattern === 'monthly') {
                    pluralLabel = 'month(s)';
                }
                return (
                    <Box key={index} sx={{ mb: 4, borderRadius: '6px', p: 3, border: '1px solid #efefef' }}>
                        <Flex sx={{ mb: 3, alignItems: 'center', gap: 2 }}>
                            <Label sx={{ width: '150px' }}>Start creating from</Label>
                            <Input
                                type="date"
                                value={task.startDate}
                                onChange={(e) => updateTask(index, 'startDate', e.target.value)}
                                sx={{ width: '200px', height: 50 }}
                            />
                        </Flex>
                        <Flex
                            sx={{ mb: 3, alignItems: 'center', gap: 2, cursor: 'pointer' }}
                            onClick={() =>
                                updateTask(
                                    index,
                                    'createWhenClientCreated',
                                    task.createWhenClientCreated ? false : true
                                )
                            }
                        >
                            <Label sx={{ width: '150px', cursor: 'pointer' }}>Create when client is created</Label>
                            <Checkbox defaultChecked={task.createWhenClientCreated} />
                        </Flex>
                        <Flex sx={{ mb: 3, alignItems: 'center', gap: '10px' }}>
                            <Label sx={{ width: '150px' }}>When to repeat</Label>
                            <Select
                                value={task.repeatPattern}
                                onChange={(e) => updateTask(index, 'repeatPattern', e.target.value)}
                                sx={{ width: '200px' }}
                            >
                                <option value="none">None</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </Select>
                            {task.repeatPattern === 'weekly' && (
                                <Select
                                    value={task.repeatDetails.dayOfWeek || ''}
                                    onChange={(e) =>
                                        updateTask(index, 'repeatDetails', {
                                            ...task.repeatDetails,
                                            dayOfWeek: e.target.value,
                                        })
                                    }
                                    sx={{ width: '300px' }}
                                >
                                    <option value="">Select Day</option>
                                    <option value="7">Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>
                                </Select>
                            )}
                            {task.repeatPattern === 'monthly' && (
                                <Flex sx={{ alignItems: 'center' }}>
                                    <Paragraph sx={{ mr: 10 }}>On: </Paragraph>
                                    <Select
                                        defaultValue={'select'}
                                        value={task.repeatDetails.monthlyOn}
                                        onChange={(e) =>
                                            updateTask(index, 'repeatDetails', {
                                                ...task.repeatDetails,
                                                monthlyOn: e.target.value,
                                            })
                                        }
                                        sx={{
                                            width: 200,
                                        }}
                                    >
                                        <option value="select">Select</option>
                                        <option value="day">A specific day</option>
                                        <option value="first">First</option>
                                        <option value="second">Second</option>
                                        <option value="third">Third</option>
                                        <option value="fourth">Fourth</option>
                                        <option value="last">Last</option>
                                        <option value="last_day">Last day</option>
                                        <option value="last_working_day">Last working day</option>
                                    </Select>
                                    {task.repeatDetails.monthlyOn === 'day' && (
                                        <Select
                                            value={task.repeatDetails.dayOfMonth || ''}
                                            onChange={(e) =>
                                                updateTask(index, 'repeatDetails', {
                                                    ...task.repeatDetails,
                                                    dayOfMonth: e.target.value,
                                                })
                                            }
                                            sx={{ ml: 10, width: '300px' }}
                                        >
                                            <option value="">Select Day of Month</option>
                                            {daysOfMonthOptions.map((day, i) => (
                                                <option key={day.value} value={day.value}>
                                                    {day.label}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                    {['first', 'second', 'third', 'fourth', 'last'].includes(
                                        task.repeatDetails.monthlyOn
                                    ) && (
                                        <Flex sx={{ flexDirection: 'row', gap: '10px', alignItems: 'center', ml: 10 }}>
                                            <Paragraph sx={{}}>Day: </Paragraph>
                                            <Select
                                                defaultValue={'select'}
                                                value={task.repeatDetails.monthlyOnDayOfWeek}
                                                onChange={(e) =>
                                                    updateTask(index, 'repeatDetails', {
                                                        ...task.repeatDetails,
                                                        monthlyOnDayOfWeek: e.target.value,
                                                    })
                                                }
                                                sx={{
                                                    width: 200,
                                                }}
                                            >
                                                <option value="select">Select</option>
                                                <option value="7">Sunday</option>
                                                <option value="1">Monday</option>
                                                <option value="2">Tuesday</option>
                                                <option value="3">Wednesday</option>
                                                <option value="4">Thursday</option>
                                                <option value="5">Friday</option>
                                                <option value="6">Saturday</option>
                                            </Select>
                                        </Flex>
                                    )}
                                </Flex>
                            )}
                            {(task.repeatPattern === 'monthly' || task.repeatPattern === 'weekly') && (
                                <Flex sx={{ ml: 10, gap: '10px', alignItems: 'center' }}>
                                    <Paragraph sx={{}}>Every</Paragraph>
                                    <Input
                                        type="number"
                                        value={task.repeatDetails.every}
                                        onChange={(e) =>
                                            updateTask(index, 'repeatDetails', {
                                                ...task.repeatDetails,
                                                every: e.target.value,
                                            })
                                        }
                                        sx={{
                                            width: '60px',
                                            textAlign: 'center',
                                            height: 50,
                                        }}
                                    />
                                    <Paragraph>{pluralLabel}</Paragraph>
                                </Flex>
                            )}
                            {task.repeatPattern === 'yearly' && (
                                <>
                                    <Select
                                        value={task.repeatDetails.dayOfMonth || ''}
                                        onChange={(e) =>
                                            updateTask(index, 'repeatDetails', {
                                                ...task.repeatDetails,
                                                dayOfMonth: e.target.value,
                                            })
                                        }
                                        sx={{ width: '300px' }}
                                    >
                                        <option value="">Select Day</option>
                                        {daysOfMonthOptions.map((day, i) => (
                                            <option key={day.value} value={day.value}>
                                                {day.label}
                                            </option>
                                        ))}
                                    </Select>
                                    <Select
                                        value={task.repeatDetails.month || ''}
                                        onChange={(e) =>
                                            updateTask(index, 'repeatDetails', {
                                                ...task.repeatDetails,
                                                month: e.target.value,
                                            })
                                        }
                                        sx={{ width: '300px' }}
                                    >
                                        <option value="">Select Month</option>
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <option key={i} value={i + 1}>
                                                {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                            </option>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </Flex>
                        <Paragraph sx={{}}>{generateTaskExplanationLabel(task)}</Paragraph>
                        <h4 style={{ fontWeight: 300, marginTop: 40 }}>When to set the relevant period end</h4>
                        {['relevantPeriodEnd'].map((dateField) =>
                            renderDateOptionRow(
                                dateField,
                                task,
                                index,
                                updateDateField,
                                monthsOffsetOptions,
                                daysOffsetOptions,
                                daysOfMonthOptions
                            )
                        )}
                        <h4 style={{ fontWeight: 300, marginTop: 40 }}>When to set the other task dates</h4>
                        {['internalDueDate', 'statutoryDueDate', 'expectedDateBilled'].map((dateField) =>
                            renderDateOptionRow(
                                dateField,
                                task,
                                index,
                                updateDateField,
                                monthsOffsetOptions,
                                daysOffsetOptions,
                                daysOfMonthOptions
                            )
                        )}
                        <Button sx={{ mt: 3 }} onClick={() => removeTask(index)} variant="light">
                            <i style={{ marginRight: '7px', color: 'red' }} className="fal fa-trash" />
                            Remove Task
                        </Button>
                    </Box>
                );
            })}
            <Button type="button" onClick={addTask} sx={{ backgroundColor: '#007bff', color: 'white' }}>
                <i style={{ marginRight: '7px' }} className="fal fa-plus-circle" />
                Schedule
            </Button>
        </Box>
    );
};

const renderDateOptionRow = (
    dateField,
    task,
    index,
    updateDateField,
    monthsOffsetOptions,
    daysOffsetOptions,
    daysOfMonthOptions
) => (
    <Box key={dateField} sx={{ mt: 3 }}>
        {dateField === 'relevantPeriodEnd' ? null : (
            <Label sx={{ textTransform: 'capitalize', mb: 1 }}>{dateField.replace(/([A-Z])/g, ' $1')}</Label>
        )}
        <Flex sx={{ alignItems: 'center', gap: 2 }}>
            <Select
                value={task.dates[dateField].type}
                onChange={(e) => updateDateField(index, dateField, 'type', e.target.value)}
                sx={{ width: '300px' }}
            >
                <option value="relative">
                    Relative to the {dateField === 'relevantPeriodEnd' ? 'creation date' : 'relevant period end'}
                </option>
                <option value="specific">Set a specific day and month</option>
            </Select>
            {task.dates[dateField].type === 'relative' ? (
                <>
                    <Select
                        value={task.dates[dateField].relation}
                        onChange={(e) => updateDateField(index, dateField, 'relation', e.target.value)}
                        sx={{ width: '300px' }}
                    >
                        <option value="before">
                            Before the {dateField === 'relevantPeriodEnd' ? 'creation date' : 'relevant period end'}
                        </option>
                        <option value="after">
                            After the {dateField === 'relevantPeriodEnd' ? 'creation date' : 'relevant period end'}
                        </option>
                    </Select>
                    <Select
                        value={task.dates[dateField].offset}
                        onChange={(e) => updateDateField(index, dateField, 'offset', e.target.value)}
                        sx={{ width: '300px' }}
                        defaultValue={''}
                    >
                        <option value="">Select how many months {task.dates[dateField].relation}</option>
                        {monthsOffsetOptions.map((month, i) => (
                            <option key={month.value} value={month.value}>
                                {month.label}
                            </option>
                        ))}
                    </Select>
                    <Select
                        value={task.dates[dateField].daysOffset}
                        onChange={(e) => updateDateField(index, dateField, 'daysOffset', e.target.value)}
                        sx={{ width: '300px' }}
                        defaultValue={''}
                    >
                        <option value="">Select how many days {task.dates[dateField].relation}</option>
                        {daysOffsetOptions.map((day, i) => (
                            <option key={day.value} value={day.value}>
                                {day.label}
                            </option>
                        ))}
                    </Select>
                </>
            ) : (
                <>
                    <Select
                        value={task.dates[dateField].relation}
                        onChange={(e) => updateDateField(index, dateField, 'relation', e.target.value)}
                        sx={{ width: '300px' }}
                    >
                        <option value="before">Before the creation date</option>
                        <option value="after">After the creation date</option>
                    </Select>
                    <Select
                        value={task.dates[dateField].dayOfMonth || ''}
                        onChange={(e) => updateDateField(index, dateField, 'dayOfMonth', e.target.value)}
                        sx={{ width: '300px' }}
                    >
                        <option value="">Select Day of Month</option>
                        {daysOfMonthOptions.map((day, i) => (
                            <option key={day.value} value={day.value}>
                                {day.label}
                            </option>
                        ))}
                    </Select>
                    <Select
                        value={task.dates[dateField].monthOfYear || ''}
                        onChange={(e) => updateDateField(index, dateField, 'monthOfYear', e.target.value)}
                        sx={{ width: '300px' }}
                    >
                        <option value="">Select Month</option>
                        {Array.from({ length: 12 }, (_, i) => (
                            <option key={i} value={i + 1}>
                                {new Date(0, i).toLocaleString('default', {
                                    month: 'long',
                                })}
                            </option>
                        ))}
                    </Select>
                </>
            )}
        </Flex>
    </Box>
);

export default TaskConfigForm;
