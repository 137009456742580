/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import { Flex, Paragraph, Button, Box, Input, Checkbox } from 'theme-ui';
import axios from 'axios';
import { TableHeaderDefinitions, TableRowDefinitions } from '../table';
import { FileSelectWdget, Title } from '../templates/add-new';
import Spinner from '../spinner';
import { defaultErrorState, defaultState } from '../../pages/definitions';
import { loadDefinitions } from '../documents/load-docs';
import { useEffect, useState } from 'react';
import { GetDocUrlViaDocId } from '../documents/load-uploads';
import { PgPagination } from '../documents/paging';
import { getSingleChat } from '../documents/load-docs';
import { ChatBot } from '../chat/chatbot';
import { downloadCsvOfChatHistory, downloadTranscript } from '../../pages/chat';

const createDefinition = async (state, updateState, errorState, updateErrorState) => {
    let errors = 0;
    let errorData = {
        selectedDocs: false,
        description: false,
        shareEmails: false,
    };

    if (state.description === '') {
        errors++;
        errorData.description = true;
    }

    if (!state.selectedDocs.length) {
        errors++;
        errorData.selectedDocs = true;
    }

    if (errors > 0) {
        updateState({ ...state, error: 'Please fix the errors in your data' });
        updateErrorState({ ...errorData });
    } else {
        updateState({ ...state, loading: true });
        updateErrorState({ ...errorState, ...errorData });

        await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_AQRU_AI_API}/uploads`,
            data: {
                share_with: [],
                description: state.description,
                type: 'definitions_extraction',
                existing_files: state?.selectedDocs || [],
            },
        });

        const { total: definitionsTotal, definitions } = await loadDefinitions();

        updateState({
            ...state,
            ...defaultState,
            loading: false,
            partialLoading: false,
            partialWordLoading: false,
            error: null,
            mode: 'list',
            definitionsTotal,
            definitions,
        });
    }
};

const commitChanges = async (initialResults, state, updateState, errorState, updateErrorState) => {
    let errors = 0;
    let errorData = {
        description: false,
    };
    const { finalisedResults } = state;

    let allKeysPresent = finalisedResults?.length > 0;

    initialResults.forEach((key) => {
        const keyTerm = key?.term;

        finalisedResults.forEach((res) => {
            const resTerm = res?.term;
            const resResults = res?.results;
            if (resTerm === keyTerm) {
                const doc1 = resResults?.doc1;
                const doc2 = resResults?.doc2;
                if (!doc1 || !doc2) {
                    allKeysPresent = false;
                }
                if (doc1 === 'no' && doc2 === 'no') {
                    allKeysPresent = false;
                }
            }
        });
    });

    if (initialResults?.length !== finalisedResults?.length || !allKeysPresent) {
        return updateState({ ...state, error: 'You need to select at least 1 change for every term.' });
    }

    if (!state?.definitionId) return;

    if (errors > 0) {
        updateState({ ...state, error: 'Please fix the errors in your data' });
        updateErrorState({ ...errorData });
    } else {
        updateState({ ...state, loading: true });
        updateErrorState({ ...errorState, ...errorData });
        try {
            await axios({
                method: 'PUT',
                url: `${process.env.REACT_APP_AQRU_AI_API}/definitions/${state?.definitionId}`,
                data: {
                    finalised_results: finalisedResults || [],
                },
            });
        } catch (e) {
            console.log(e);
        }
        const { total: definitionsTotal, definitions } = await loadDefinitions();

        updateState({
            ...state,
            loading: false,
            partialLoading: false,
            partialWordLoading: false,
            error: null,
            mode: 'edit',
            definitionsTotal,
            definitions,
        });

        document.documentElement.scrollTop = 0;
    }
};

const generateClipBoardContent = async (definitionObjectInView, toggleCopied) => {
    const { aggregated_results, finalised_results } = definitionObjectInView;
    let templateString = ``;
    if (!aggregated_results || aggregated_results?.length < 1) return;

    aggregated_results.forEach((item, i) => {
        const ResultTouse = finalised_results.find((res) => res?.term === item?.term) || {};
        const { doc1, doc2 } = ResultTouse.results;
        const { doc1: doc1Result, doc2: doc2Result } = item.results;
        templateString += `${item?.term}\n`;
        if (doc1 === 'yes') {
            templateString += `${doc1Result}\n`;
        }
        if (doc1 == 'yes' && doc2 == 'yes') {
            templateString += `\n`;
        }
        if (doc2 === 'yes') {
            templateString += `${doc2Result}\n`;
        }

        templateString += `\n\n`;
    });

    toggleCopied(true);
    navigator.clipboard.writeText(templateString);
    setTimeout(() => {
        toggleCopied(false);
    }, 3000);
};

export const Create = ({ state, updateState, errorState, updateErrorState, isMobile, navigate }) => {
    return (
        <Flex
            sx={{
                width: '1200px',
                minHeight: '450px',
                flexDirection: 'row',
                border: '0px red solid',
                mt: '50px',
            }}
        >
            {/* 1 */}
            <Flex
                sx={{
                    flexDirection: 'column',
                    pr: '40px',
                    width: '460px',
                    borderRight: '1px solid #a3a69b',
                }}
            >
                <Title text={`Select Documents`} subtitle="Select the 2 files from your library to compare" />
                <Box sx={{ mb: '20px' }} />
                {state?.partialLoading && <Spinner />}
                {!state?.partialLoading && (
                    <FileSelectWdget
                        state={state}
                        updateState={updateState}
                        total={state.docTotal}
                        limit={state.docLimit}
                        type="docsOnly"
                        documents={state?.documents}
                        selectedDocs={state?.selectedDocs}
                        showFilters
                        selectedDocVar="selectedDocs"
                        maxFilesToSelect={2}
                    />
                )}
            </Flex>
            {/* 2 */}
            <Flex sx={{ flexDirection: 'column', mr: '40px', pl: '40px' }}>
                <Title
                    text="Description"
                    subtitle="Please provide a short description of the documents you wish to compare"
                />

                <Input
                    sx={{
                        mt: '10px',
                        mb: '25px',
                        width: '400px',
                        background: '#FFFFFF',
                        border: errorState?.description ? '1px solid red' : '1px solid #a3a69b',
                        borderRadius: '10px',
                        height: '40px',
                        fontSize: '14px',
                    }}
                    type="text"
                    id="description"
                    data-testid="description"
                    name="description"
                    label=""
                    placeholder="Description..."
                    onChange={(e) => updateState({ ...state, description: e.target.value })}
                    error={null}
                />
                {/* <Flex sx={{ minHeight: '250px', flexDirection: 'column' }}>
                    <Title
                        text="Share with"
                        subtitle="Add the emails of people you wish to share the generated doc with"
                    />

                    <Input
                        sx={{
                            mt: '10px',
                            mb: '25px',
                            width: '400px',
                            background: '#FFFFFF',
                            border: errorState?.shareEmails ? '1px solid red' : '1px solid #a3a69b',
                            borderRadius: '10px',
                            height: '40px',
                            fontSize: '14px',
                        }}
                        type="text"
                        id="shareEmails"
                        data-testid="shareEmails"
                        name="shareEmails"
                        label=""
                        value={state?.shareEmails}
                        placeholder="test@example.com,admin@example.com"
                        onChange={(e) => updateState({ ...state, shareEmails: e.target.value })}
                        error={null}
                    />
                </Flex> */}
                <Flex sx={{ mb: '20px' }} />
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        mt: state?.templateToGenerate === 'word' ? '0' : '40px',
                        width: '400px',
                    }}
                >
                    <Button
                        variant="primary"
                        sx={{
                            width: '180px',
                            mt: '0',
                            alignSelf: 'flex-end',
                            backgroundColor: 'white',
                            color: 'text',
                            border: '1px solid #0C1B2A',
                        }}
                        data-testid="submit"
                        onClick={async () => {
                            updateState({
                                ...state,
                                mode: 'list',
                                error: null,
                            });
                            updateErrorState(defaultErrorState);
                        }}
                    >
                        Cancel
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '13px',
                                marginLeft: '9px',
                            }}
                            className={`fas fa-times-circle`}
                        />
                    </Button>
                    <Button
                        variant="primary"
                        sx={{
                            width: '180px',
                            mt: '0',
                            alignSelf: 'flex-end',
                            backgroundColor: 'primary',
                        }}
                        data-testid="submit"
                        onClick={async () => await createDefinition(state, updateState, errorState, updateErrorState)}
                    >
                        Create
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '13px',
                                marginLeft: '9px',
                            }}
                            className={`fas fa-plus-circle`}
                        />
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const List = ({ state, updateState, isMobile, navigate }) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                ml: '0px',
                mt: '50px',
                width: '100%',
            }}
        >
            <Flex
                sx={{
                    mt: '0px',
                    width: '100%',
                    minHeight: ['auto', '70px'],
                    border: '1px solid #eaecf0',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    borderTopRightRadius: 12,
                    borderTopLeftRadius: 12,
                }}
            >
                <TableHeaderDefinitions isMobile={isMobile} />

                {state.definitions.map((item, i) => {
                    return (
                        <TableRowDefinitions
                            isMobile={isMobile}
                            key={`table_row_${i}`}
                            item={item}
                            i={i}
                            navigate={navigate}
                            state={state}
                            updateState={updateState}
                        />
                    );
                })}
            </Flex>
            <PgPagination
                currentState={state}
                updateState={updateState}
                totalPages={state.definitionsTotal ? Math.ceil(state.definitionsTotal / state.definitionsLimit) : 1}
                totalRecords={state.definitionsTotal}
                type="definitions"
            />
        </Flex>
    );
};

const isItemSelected = (term, state, docKey) => {
    const { finalisedResults } = state;
    const theTerm = finalisedResults.find((item) => item?.term === term) || false;

    if (!theTerm) return false;
    const isSelected = theTerm?.results?.[docKey] === 'yes';
    return isSelected;
};

const toggleSelectedItem = (isSelected, term, state, updateState, docKey) => {
    const { finalisedResults } = state;
    const theTerm = finalisedResults.find((item) => item?.term === term) || {};

    const filteredArr = finalisedResults.filter((item) => item?.term !== term);

    const updatedResults = [
        ...filteredArr,
        {
            term: `${term}`,
            results: {
                doc1: theTerm?.results?.doc1 || 'no',
                doc2: theTerm?.results?.doc2 || 'no',
                [docKey]: isSelected ? 'no' : 'yes',
            },
        },
    ];

    updateState({ ...state, finalisedResults: updatedResults, error: null });
    return;
};

const EditItem = ({ state, updateState, item, lastItem, initialResults }) => {
    const {
        term,
        results: { doc1, doc2 },
    } = item;

    return (
        <Box sx={{ mb: '50px', pb: '60px', borderBottom: '1px solid lightgray' }}>
            <Paragraph sx={{ width: '100%', fontSize: '19px', textAlign: 'left', mb: '30px' }}>
                Term: <span style={{ fontWeight: '600' }}>{term}</span>
            </Paragraph>

            <Flex sx={{ justifyContent: 'space-between' }}>
                <Flex sx={{ width: '500px', minHeight: '100px', border: '0px solid blue', flexDirection: 'column' }}>
                    <Flex
                        sx={{ mb: '10px' }}
                        onClick={() => {
                            const isSelected = isItemSelected(term, state, 'doc1');
                            toggleSelectedItem(isSelected, term, state, updateState, 'doc1');
                        }}
                    >
                        <Paragraph sx={{ fontWeight: '600', mb: '10px', mr: '15px' }}>Document 1</Paragraph>
                        <Checkbox
                            sx={{ mt: '-2px' }}
                            checked={isItemSelected(term, state, 'doc1')}
                            onChange={() => {}}
                        />
                    </Flex>
                    <Paragraph sx={{ minHeight: '50px' }}>{doc1}</Paragraph>
                </Flex>
                <Flex sx={{ width: '1px', height: 'auto', borderLeft: '1px solid lightgray' }} />
                <Flex sx={{ width: '500px', minHeight: '100px', border: '0px solid blue', flexDirection: 'column' }}>
                    <Flex
                        sx={{ mb: '10px' }}
                        onClick={() => {
                            const isSelected = isItemSelected(term, state, 'doc2');
                            toggleSelectedItem(isSelected, term, state, updateState, 'doc2');
                        }}
                    >
                        <Paragraph sx={{ fontWeight: '600', mb: '10px', mr: '15px' }}>Document 2</Paragraph>
                        <Checkbox
                            sx={{ mt: '-2px' }}
                            checked={isItemSelected(term, state, 'doc2')}
                            onChange={() => {}}
                        />
                    </Flex>{' '}
                    <Paragraph sx={{ minHeight: '50px' }}>{doc2}</Paragraph>
                </Flex>{' '}
            </Flex>
        </Box>
    );
};

const CopyToClipboard = ({ generateClipBoardContent, definitionObjectInView, copied, toggleCopied }) => (
    <Paragraph
        sx={{
            p: '10px 5px',
            width: '95px',
            height: '40px',
            ml: '15px',
            mb: '45px',
            fontSize: '13px',
            cursor: 'pointer',
            border: '1px solid lightgray',
            color: copied ? 'green' : 'initial',
        }}
        onClick={() => generateClipBoardContent(definitionObjectInView, toggleCopied)}
    >
        <i
            style={{
                marginTop: '0px',
                fontSize: '14px',
                marginLeft: '9px',
                marginRight: '6px',
            }}
            className={`far fa-copy`}
        />{' '}
        {copied ? 'Copied' : 'Copy'}
    </Paragraph>
);

const TextIconAndAction = ({ text, icon, action }) => (
    <Flex
        sx={{ height: '30px', justifyContent: 'flex-start', cursor: 'pointer' }}
        onClick={() => {
            action();
        }}
    >
        <Paragraph
            sx={{
                fontSize: '14px',
                fontWeight: '500',
                mt: '15px',
                textTransform: 'capitalize',
            }}
        >
            <i
                style={{
                    marginTop: '0px',
                    marginRight: '6px',
                    fontSize: '15px',
                }} // @ts-ignore
                className={`${icon}`}
            />
            {text}
        </Paragraph>
        <Paragraph
            sx={{
                fontSize: '14px',
                fontWeight: '600',
                mt: '13px',
                ml: '40px',
            }}
        >
            <i
                style={{
                    marginTop: '0px',
                    fontSize: '14px',
                }} // @ts-ignore
                className={`fas fa-external-link`}
            />
        </Paragraph>
    </Flex>
);

const ChatMini = ({ state, updateState, docIds, stateRef, user, queryDocument, type = 'default' }) => {
    return (
        <Flex
            sx={{
                width: '400px',
                height: '600px',
                border: '1px white solid',
                boxShadow: ' 0px 0px 12px rgba(0,0,0,0.2)',
                position: 'fixed',
                bottom: '0',
                right: '100px',
                backgroundColor: 'white',
                pb: '30px',
            }}
        >
            <Flex sx={{ margin: '10px 0px 40px', flex: 1, height: '100%', border: '0px lightgray solid' }}>
                {docIds?.length < 1 && <Paragraph> loading docs</Paragraph>}

                {docIds?.length > 0 && (
                    <ChatBot
                        state={state}
                        updateState={updateState}
                        queryDocument={queryDocument}
                        stateRef={stateRef}
                        downloadCsvOfChatHistory={downloadCsvOfChatHistory}
                        downloadTranscript={downloadTranscript}
                        user={user}
                        type={type}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export const Edit = ({
    state,
    updateState,
    isMobile,
    navigate,
    errorState,
    updateErrorState,
    stateRef,
    user,
    queryDocument,
}) => {
    const { definitionObjectInView, finalisedResults } = state;
    const [copied, toggleCopied] = useState(false);
    const [showMiniChat, toggleMiniChat] = useState(false);
    const [docIds, updateDocIds] = useState([]);
    const { aggregated_results: Results, documents } = definitionObjectInView;
    const initialResults = [];
    const documentHasBeenGenerated = !!definitionObjectInView?.finalised_results?.length > 0;

    useEffect(() => {
        if (documentHasBeenGenerated) {
            updateState({ ...state, finalisedResults: definitionObjectInView?.finalised_results });
        }
    }, []);

    useEffect(() => {
        const docArr = [];
        documents?.map((doc) => {
            const { id } = doc;
            docArr.push(id);
        });
        updateDocIds(docArr);
    }, [documents]);

    useEffect(() => {
        (async () => {
            const { chat_id } = definitionObjectInView;
            if (chat_id) {
                const data = await getSingleChat(chat_id);
                updateState({
                    ...state,
                    loading: false,
                    documentToQuery: data,
                    selectedDocs: docIds,
                    selectedChatId: chat_id,
                });
            }
        })();
    }, [docIds]);

    // console.log({ documents });
    // console.log({ definitionObjectInView });
    return (
        <Flex
            sx={{
                width: '1200px',
                minHeight: '450px',
                flexDirection: 'column',
                border: '0px red solid',
                mt: '0px',
                mb: '10px',
                position: 'relative',
            }}
        >
            {showMiniChat && (
                <ChatMini
                    state={state}
                    updateState={updateState}
                    docIds={docIds}
                    stateRef={stateRef}
                    user={user}
                    queryDocument={queryDocument}
                    type="definitions"
                />
            )}
            <Flex sx={{ width: '100%', minHeight: '400px', border: '0px red solid', flexDirection: 'column' }}>
                <Flex>
                    <Paragraph
                        sx={{
                            width: '95px',
                            height: '40px',
                            p: '10px 5px',
                            mb: documentHasBeenGenerated ? '15px' : '45px',
                            fontSize: '13px',
                            cursor: 'pointer',
                            border: '1px solid lightgray',
                        }}
                        onClick={async () => {
                            updateState({
                                ...state,
                                mode: 'list',
                                error: null,
                                finalisedResults: [],
                                chatHistory: [],
                                documentToQuery: {},
                                documentQuery: '',
                            });
                            updateErrorState(defaultErrorState);
                        }}
                    >
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '12px',
                                marginLeft: '9px',
                                marginRight: '6px',
                            }}
                            className={`fal fa-chevron-left`}
                        />{' '}
                        Back
                    </Paragraph>
                    <Paragraph
                        sx={{
                            width: '95px',
                            height: '40px',
                            p: '10px 5px',
                            ml: '20px',
                            mb: documentHasBeenGenerated ? '15px' : '45px',
                            fontSize: '13px',
                            cursor: 'pointer',
                            border: '1px solid lightgray',
                        }}
                        onClick={async () => {
                            toggleMiniChat(!showMiniChat);
                        }}
                    >
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '12px',
                                marginLeft: '9px',
                                marginRight: '6px',
                            }}
                            className={`far fa-comments`}
                        />{' '}
                        Chat
                    </Paragraph>
                    {documentHasBeenGenerated && (
                        <CopyToClipboard
                            generateClipBoardContent={generateClipBoardContent}
                            definitionObjectInView={definitionObjectInView}
                            copied={copied}
                            toggleCopied={toggleCopied}
                        />
                    )}
                </Flex>

                {documentHasBeenGenerated && (
                    <Paragraph sx={{ fontSize: '15px', mb: '25px', fontWeight: '500', color: 'green' }}>
                        If you make changes to your selections, please save again before selecting copy, otherwise it
                        will be the old data.
                    </Paragraph>
                )}

                {documents?.length > 0 && (
                    <Flex
                        sx={{
                            width: '100%',
                            minHeight: '100px',
                            borderBottom: '1px solid lightgray',
                            pb: '40px',
                            mb: '30px',
                            mt: '10px',
                            flexDirection: 'column',
                        }}
                    >
                        <Paragraph sx={{ fontSize: '18px', mb: '15px', fontWeight: '500' }}>
                            Files used in this draft:
                        </Paragraph>
                        {documents?.length === 1 && (
                            <Paragraph sx={{ fontSize: '13px', mb: '15px', fontWeight: '500', color: 'red' }}>
                                There appears to only be one file available for your draft.
                            </Paragraph>
                        )}

                        {documents?.map((doc) => {
                            const { file_name: fileName, id } = doc;
                            return (
                                <TextIconAndAction
                                    key={`${fileName}`}
                                    text={fileName}
                                    icon="fal fa-file"
                                    action={async () => {
                                        updateState({ ...state, loading: true });
                                        const data = await GetDocUrlViaDocId(id);
                                        updateState({
                                            ...state,
                                            loading: false,
                                        });
                                        window.open(data?.url);
                                    }}
                                />
                            );
                        })}
                    </Flex>
                )}

                {Results?.map((res, i) => {
                    const lastItem = i === Results?.length - 1;
                    const docObject = {
                        term: res?.term,
                        results: {
                            doc1: null,
                            doc2: null,
                        },
                    };
                    initialResults.push(docObject);

                    return (
                        <EditItem
                            key={`item-${i}`}
                            item={res}
                            state={state}
                            updateState={updateState}
                            lastItem={lastItem}
                            initialResults={initialResults}
                        />
                    );
                })}
            </Flex>
            <Flex sx={{ mb: '20px' }} />
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    mt: state?.templateToGenerate === 'word' ? '0' : '40px',
                    width: '400px',
                }}
            >
                <Button
                    variant="primary"
                    sx={{
                        width: '180px',
                        mt: '0',
                        alignSelf: 'flex-end',
                        backgroundColor: 'white',
                        color: 'text',
                        border: '1px solid #0C1B2A',
                    }}
                    data-testid="cancel"
                    onClick={async () => {
                        updateState({
                            ...state,
                            mode: 'list',
                            error: null,
                            finalisedResults: [],
                        });
                        updateErrorState(defaultErrorState);
                    }}
                >
                    Cancel
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '13px',
                            marginLeft: '9px',
                        }}
                        className={`fas fa-times-circle`}
                    />
                </Button>
                <Button
                    variant="primary"
                    sx={{
                        width: '180px',
                        mt: '0',
                        alignSelf: 'flex-end',
                        backgroundColor: 'primary',
                    }}
                    data-testid="submit"
                    onClick={async () => {
                        updateState({ ...state, error: null });
                        await commitChanges(initialResults, state, updateState, errorState, updateErrorState);
                    }}
                >
                    Save
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '13px',
                            marginLeft: '9px',
                        }}
                        className={`fas fa-plus-circle`}
                    />
                </Button>
            </Flex>
        </Flex>
    );
};
