/* eslint-disable no-undef */
import { Button, Flex, Paragraph } from 'theme-ui';
import { PgPaginationV2 } from '../documents/paging';
import axios from 'axios';

const deleteTeam = (id, state, updateState) => async () => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams/${id}`,
            method: 'DELETE',
        });

        const {
            data: { teams, total: totalTeams },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams`,
        });

        updateState({ ...state, loading: false, error: null, teams, totalTeams });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            error: 'Uh oh, there has been an error removing this team from your organisation',
        });
    }
};

const Teams = ({ organisation, updateState, state }) => (
    <Flex sx={{ flexDirection: 'column' }}>
        {organisation?.user?.role === 'admin' && (
            <Button
                sx={{
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    color: 'text',
                    fontSize: 14,
                    height: 40,
                    width: 150,
                    mt: 20,
                }}
                onClick={() =>
                    updateState({
                        ...state,
                        teamsView: 'manage',
                        team: null,
                    })
                }
            >
                Create team
            </Button>
        )}

        <Flex sx={{ flexDirection: 'column', mt: 20, fontSize: 14 }}>
            <Flex
                sx={{
                    width: 600,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    borderTop: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    backgroundColor: 'lightGrey',
                    boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Flex
                    sx={{
                        padding: 20,

                        wordWrap: 'break-word',
                        width: 500,
                        borderRight: '1px solid #ccc',
                    }}
                >
                    <Paragraph sx={{ fontWeight: 600 }}>Name</Paragraph>
                </Flex>
                <Flex sx={{ width: 100 }}></Flex>
            </Flex>
            {state?.teams?.map((x, idx) => (
                <Flex
                    key={x.name}
                    sx={{
                        boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                        borderLeft: '1px solid #ccc',
                        borderRight: '1px solid #ccc',
                        borderBottom: idx === state.teams.length - 1 ? '1px solid #ccc' : 'unset',
                        width: 600,
                    }}
                >
                    <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}>
                        <Paragraph>{x.name}</Paragraph>
                    </Flex>
                    <Flex sx={{ padding: 20, width: 100, justifyContent: 'flex-end' }}>
                        {organisation?.user?.role === 'admin' && (
                            <>
                                <i
                                    style={{
                                        marginLeft: '10px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                    }}
                                    className="fas fa-pencil"
                                    aria-hidden="true"
                                    onClick={() =>
                                        updateState({
                                            ...state,
                                            teamsView: 'manage',
                                            team: x,
                                            newTeamName: x.name,
                                        })
                                    }
                                />

                                <i
                                    style={{
                                        marginLeft: '30px',
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        color: 'red',
                                    }}
                                    className="fal fa-trash"
                                    aria-hidden="true"
                                    onClick={() =>
                                        updateState({
                                            ...state,
                                            showDeleteTeamConfirmation: true,
                                            confirmCallback: deleteTeam(x.id, state, updateState),
                                        })
                                    }
                                />
                            </>
                        )}
                    </Flex>
                </Flex>
            ))}
            <Flex sx={{ width: 600 }}>
                <PgPaginationV2
                    currentState={state}
                    updateState={updateState}
                    totalPages={state.totalTeams ? Math.ceil(state.totalTeams / state.teamsLimit) : 1}
                    totalRecords={state.totalTeams}
                    offsetKey="teamsOffset"
                    totalKey="totalTeams"
                    limitKey="teamsLimit"
                />
            </Flex>
        </Flex>
    </Flex>
);

export default Teams;
