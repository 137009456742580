import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Flex, Paragraph } from 'theme-ui';

const AccordionItem = ({ title, children, isOpen, onToggle }) => (
    <Flex sx={{ flexDirection: 'column', mb: isOpen ? 30 : -20 }}>
        <Flex>
            <Button
                onClick={onToggle}
                sx={{
                    backgroundColor: 'primary',
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15,
                    color: 'white',
                    padding: 10,
                    width: '100%',
                    textAlign: 'left',
                    cursor: 'pointer',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: 0,
                    mb: 40,
                    border: '1px solid #efefef',
                    boxShadow: 'none',
                    height: 50,
                }}
            >
                <Paragraph>{title}</Paragraph>
                <i className={`fa ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} accordion-icon`}></i>
            </Button>
        </Flex>
        <AnimatePresence initial={false}>
            {isOpen && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                    className="accordion-content-wrapper"
                >
                    <div className="accordion-content">{children}</div>
                </motion.div>
            )}
        </AnimatePresence>
    </Flex>
);

const Accordion = ({ defaultOpenIndex, items }) => {
    const [openIndexes, setOpenIndexes] = useState([defaultOpenIndex]);

    const handleToggle = (index) => {
        setOpenIndexes(openIndexes?.includes(index) ? openIndexes.filter((x) => x !== index) : [...openIndexes, index]);
    };

    return (
        <Flex
            sx={{
                width: '100%',
                maxWidth: 900,
                flexDirection: 'column',
                ml: 10,
            }}
        >
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    isOpen={openIndexes.includes(index)}
                    onToggle={() => handleToggle(index)}
                >
                    {item.content}
                </AccordionItem>
            ))}
        </Flex>
    );
};

export default Accordion;
