/* eslint-disable no-undef */
import { Button, Flex, Image, Paragraph, Link, Input } from 'theme-ui';
import ReactSelect from 'react-select';
import axios from 'axios';
import { loadAvatar } from './my-profile';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../tasks/confirm-dialog';
import { toast, ToastContainer } from 'react-toastify';

export const roles = [
    { value: 'user', label: 'User' },
    { value: 'admin', label: 'Admin' },
];

const updateRole = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/set-role`,
            method: 'POST',
            data: {
                email_address: state.user.email_address,
                role: state.newRole ? state.newRole?.value : state.user?.role,
                additional_data: {
                    ...(state.user?.additional_data || {}),
                    billable_hourly_amount: state.user?.additional_data?.billable_hourly_amount,
                },
            },
        });

        const {
            data: { users, total: usersTotal },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
        });

        updateState({ ...state, loading: false, editError: null, confirmMessage: 'User updated', users, usersTotal });
    } catch (e) {
        console.log(e);
        toast(e?.response?.data?.message || 'Uh oh, there has been an error updating this user', { type: 'error' });
    }
};

const resetMfa = async (state, updateState, emailAddress) => {
    try {
        updateState({ ...state, loading: true });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/reset-mfa`,
            method: 'POST',
            data: {
                email_address: emailAddress?.trim()?.toLowerCase(),
            },
        });

        updateState({ ...state, loading: false });

        toast('MFA reset', {
            type: 'success',
            autoClose: 5000,
            position: 'top-right',
        });
    } catch (e) {
        updateState({ ...state, loading: false });

        toast("We have been unable to reset this user's MFA, please try again later", {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

const EditUser = ({ state, updateState }) => {
    const avatarUrl = loadAvatar({ user: state.user });

    const [localState, updateLocalState] = useState({ avatarData: null });

    useEffect(() => {
        if (avatarUrl) {
            (async () => {
                const res = await axios.get(avatarUrl);
                updateLocalState({ ...localState, avatarData: res.data });
            })();
        }
    }, [avatarUrl]);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            {state.showResetMfaConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showResetMfaConfirmation"
                    text="Are you sure you wish to reset the MFA for this user?"
                />
            )}

            <Flex sx={{ mt: 20 }}>
                <Paragraph
                    sx={{
                        width: '95px',
                        height: '40px',
                        p: '10px 5px',
                        mb: '25px',
                        fontSize: '13px',
                        cursor: 'pointer',
                        border: '1px solid #eaecf0',
                        color: 'text',
                        borderRadius: 30,
                    }}
                    onClick={async () => {
                        updateState({
                            ...state,
                            userView: 'list',
                            user: null,
                            newRole: null,
                            confirmMessage: null,
                            editError: null,
                            error: null,
                        });
                    }}
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '12px',
                            marginLeft: '9px',
                            marginRight: '6px',
                            color: 'text',
                        }}
                        className={`fal fa-chevron-left`}
                    />{' '}
                    Back
                </Paragraph>
                <Link sx={{ textDecoration: 'none' }} target="_blank" href={`/tasks?user_id=${state.user.raw_id}`}>
                    <Paragraph
                        sx={{
                            width: '115px',
                            height: '40px',
                            p: '10px 5px',
                            mb: '25px',
                            fontSize: '13px',
                            cursor: 'pointer',
                            border: '1px solid #eaecf0',
                            ml: 10,
                            textAlign: 'center',
                            borderRadius: 30,
                        }}
                    >
                        View Tasks
                    </Paragraph>
                </Link>
                <Button
                    onClick={async () => {
                        updateState({
                            ...state,
                            showResetMfaConfirmation: true,
                            confirmCallback: async () => {
                                await resetMfa(state, updateState, state.user.email_address);
                            },
                        });
                    }}
                    variant="light"
                    sx={{ height: 38, ml: 10 }}
                >
                    Reset MFA
                </Button>
            </Flex>

            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ mt: 20, flexDirection: 'column' }}>
                        <Paragraph sx={{ width: 110, fontWeight: 600 }}>Email address</Paragraph>
                        <Paragraph sx={{ mt: 10 }}>{state.user.email_address}</Paragraph>
                    </Flex>
                    <Flex sx={{ mt: 20, flexDirection: 'column' }}>
                        <Paragraph sx={{ width: 110, fontWeight: 600 }}>First Name</Paragraph>
                        <Paragraph sx={{ mt: 10 }}>{state.user.first_name}</Paragraph>
                    </Flex>
                    <Flex sx={{ mt: 20, flexDirection: 'column' }}>
                        <Paragraph sx={{ width: 110, fontWeight: 600 }}>Last Name</Paragraph>
                        <Paragraph sx={{ mt: 10 }}>{state.user.last_name}</Paragraph>
                    </Flex>

                    <Flex sx={{ mt: 30, flexDirection: 'column', width: 300 }}>
                        <Paragraph sx={{ mr: 30, fontWeight: 600 }}>Role</Paragraph>
                        <ReactSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '300px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    newRole: c,
                                });
                            }}
                            placeholder="Select a role for your user"
                            value={state.newRole || roles.find((x) => x.value === state.user.role)}
                            options={roles}
                        />
                    </Flex>

                    <Flex sx={{ mt: 20, flexDirection: 'column', width: 300 }}>
                        <Paragraph sx={{ mr: 30, mb: 10, fontWeight: 600 }}>Billable Hourly Amount</Paragraph>
                        <Input
                            sx={{}}
                            type="number"
                            onChange={async (e) => {
                                updateState({
                                    ...state,
                                    user: {
                                        ...state.user,
                                        additional_data: {
                                            ...state.user?.additional_data,
                                            billable_hourly_amount: e.target.value,
                                        },
                                    },
                                });
                            }}
                            placeholder="Set a billable hourly amount"
                            value={state.user?.additional_data?.billable_hourly_amount}
                        />
                    </Flex>

                    <Button
                        sx={{
                            border: '1px solid #ccc',
                            backgroundColor: 'white',
                            color: '#000',
                            fontSize: 14,
                            height: 40,
                            width: 150,
                            mt: 40,
                        }}
                        onClick={async () => {
                            await updateRole(state, updateState);
                        }}
                    >
                        Update
                    </Button>

                    {state.editError && <Paragraph sx={{ mt: 20, color: 'red' }}>{state.editError}</Paragraph>}
                    {state.confirmMessage && (
                        <Paragraph sx={{ mt: 20, color: 'green' }}>{state.confirmMessage}</Paragraph>
                    )}
                </Flex>
                <Flex>
                    {localState.avatarData && (
                        <Image
                            sx={{ width: 150, height: 150, ml: 80, mt: 30 }}
                            src={localState.avatarData}
                            alt="Preview"
                        />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EditUser;
