/* eslint-disable no-undef */
import axios from 'axios';
import ReactSelect from 'react-select';
import { Button, Flex, Label, Switch } from 'theme-ui';
import { downloadTasksToCsv } from './api';
import { defaultColumnOptions } from './filters';

const ButtonToolbar = ({ state, updateState, loadTaskData, taskId }) => (
    <Flex sx={{ ml: 10, mt: 0 }}>
        <Button
            variant="primary"
            sx={{
                width: '110px',
                backgroundColor: 'primary',
                ml: 20,
                height: 40,
                fontSize: '14px',
            }}
            data-testid="submit"
            onClick={async () => {
                let assignedTo;
                let reviewer;
                let partner;
                if (state.clientSelected?.[0]?.value) {
                    updateState({
                        ...state,
                        loading: true,
                    });

                    const { data: client } = await axios.get(
                        `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientSelected?.[0]?.value}/relations`,
                        {
                            params: {
                                raw_id: '1',
                            },
                        }
                    );

                    assignedTo =
                        client?.relations
                            ?.filter((x) => x.type === 'assigned_to')
                            ?.map((x) => ({
                                value: x.id,
                                label: x.email_address,
                            })) || [];

                    reviewer =
                        client?.relations
                            ?.filter((x) => x.type === 'reviewer')
                            ?.map((x) => ({
                                value: x.id,
                                label: x.email_address,
                            })) || [];

                    partner =
                        client?.relations
                            ?.filter((x) => x.type === 'partner')
                            ?.map((x) => ({
                                value: x.id,
                                label: x.email_address,
                            })) || [];
                }

                updateState({
                    ...state,
                    loading: false,
                    showTaskOverlay: true,
                    comments: [],
                    totalComments: 0,
                    mode: 'new',
                    lastAutoSaved: null,
                    fullTaskData: {
                        client_for_component: state.clientSelected?.length ? state.clientSelected?.[0] : null,
                        assigned_to_for_component: assignedTo,
                        reviewer_for_component: reviewer,
                        partner_for_component: partner,
                    },
                });
            }}
        >
            <i
                style={{
                    marginTop: '0px',
                    fontSize: '13px',
                    marginRight: '9px',
                }}
                className={`fas fa-plus-circle`}
            />
            New
        </Button>
        <Button
            sx={{
                ml: 20,
                backgroundColor: state.filter === 'my_tasks' ? '#CCC' : 'white',
                color: 'text',
                border: '1px solid #a3a69b',
                height: 40,
                fontSize: '13px',
            }}
            onClick={async () => {
                document.getElementById('task_title_filter').value = null;
                const tempState = {
                    ...state,
                    filter: state.filter === 'my_tasks' ? null : 'my_tasks',
                    show_my_tasks: state.filter === 'my_tasks' ? '0' : '1',
                    priority: '',
                    assignedTo: '',
                    clientSelected: '',
                    team: '',
                    searchByTitle: '',
                };
                updateState(tempState);
                await loadTaskData(tempState, updateState);
                window.localStorage.setItem('myTasksFilter', state.filter === 'my_tasks' ? '' : 'my_tasks');
            }}
        >
            Mine
        </Button>
        <a href="/schedule" target="_blank">
            <Button
                sx={{
                    ml: 20,
                    backgroundColor: 'white',
                    color: 'text',
                    border: '1px solid #a3a69b',
                    height: 40,
                    fontSize: '13px',
                }}
            >
                Scheduled
            </Button>
        </a>
        <Button
            sx={{
                ml: 20,
                backgroundColor: state.show_tasks_with_cloning_setup === '1' ? '#CCC' : 'white',
                color: 'text',
                border: '1px solid #a3a69b',
                height: 40,
                fontSize: '13px',
            }}
            onClick={async () => {
                const tempState = {
                    ...state,
                    show_tasks_with_cloning_setup: state.show_tasks_with_cloning_setup === '1' ? null : '1',
                };
                updateState(tempState);
                await loadTaskData(tempState, updateState);
            }}
        >
            Repeated
        </Button>
        <Button
            sx={{
                ml: 20,
                backgroundColor: state.filter === 'overdue_tasks' ? '#CCC' : 'white',
                color: 'text',
                border: '1px solid #a3a69b',
                height: 40,
                fontSize: '13px',
            }}
            onClick={async () => {
                const tempState = {
                    ...state,
                    filter: state.filter === 'overdue_tasks' ? null : 'overdue_tasks',
                };
                updateState(tempState);
                await loadTaskData(tempState, updateState);
            }}
        >
            Overdue
        </Button>
        {state.view === 'list' && (
            <Button
                sx={{
                    ml: 20,
                    backgroundColor: 'white',
                    color: 'text',
                    border: '1px solid #a3a69b',
                    height: 40,
                    fontSize: '13px',
                }}
                onClick={async () => {
                    downloadTasksToCsv(state.tasks_for_list, state.organisation);
                }}
            >
                <i
                    style={{
                        marginTop: '0px',
                        marginRight: '6px',
                        color: 'text',
                    }}
                    className={`fas fa-download`}
                />
                Download
            </Button>
        )}
        <Button
            variant="primary"
            sx={{
                width: 110,
                height: '40px',
                backgroundColor: 'white',
                color: 'text',
                border: '1px solid #a3a69b',
                ml: 20,
                fontSize: '13px',
            }}
            data-testid="submit"
            onClick={async () => {
                clearTaskFilters();

                const statuses = (
                    state?.organisation?.default_visualisation?.statuses?.map((x) => ({
                        value: x.key,
                        label: x.label,
                    })) || []
                )?.filter((x) => x.value !== 'closed' && x.value !== 'done');

                window.localStorage.setItem('statuses', statuses ? JSON.stringify(statuses) : '');

                await loadTaskData(
                    {
                        ...state,
                        priority: '',
                        maxTasks: {
                            value: 100,
                            label: '100 tasks max',
                        },
                        assignedTo: '',
                        partners: '',
                        reviewers: '',
                        serviceFilter: '',
                        statuses,
                        clientSelected: '',
                        team: '',
                        searchByTitle: '',
                        filter: null,
                        filters: [],
                        statutory_due_date: null,
                        internal_due_date: null,
                        show_my_tasks: '0',
                        customColumn: null,
                        customColumnOperator: null,
                        customColumnFilterValue: null,
                    },
                    updateState
                );
            }}
        >
            <i
                style={{
                    marginTop: '0px',
                    marginRight: '6px',
                    color: 'text',
                }}
                className={`fas fa-times-circle`}
            />
            Clear
        </Button>

        {state.mode === 'list' && !taskId && (
            <Flex sx={{ ml: 30, width: 260, mt: 10 }}>
                <Label sx={{ mt: '3px', mr: 10 }}>Kanban view</Label>
                <Switch
                    defaultChecked={state.view === 'list'}
                    onClick={() => {
                        updateState({
                            ...state,
                            view: state.view === 'list' ? 'kanban' : 'list',
                        });
                        window.localStorage.setItem('task_view', state.view === 'list' ? 'kanban' : 'list');
                    }}
                />
                <Label sx={{ ml: -30, mt: '3px' }}>List view</Label>
            </Flex>
        )}
        <ReactSelect
            styles={{
                control: (provided) => ({
                    ...provided,
                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                    width: '170px',
                    minHeight: '40px',
                    marginTop: '0px',
                    borderRadius: 10,
                    marginBottom: '10px',
                    border: '1px solid #a3a69b',
                    fontSize: '14px',
                }),
            }}
            onChange={async (c) => {
                await loadTaskData({ ...state, maxTasks: c }, updateState);
                window.localStorage.setItem('maxTasks', JSON.stringify(c));
            }}
            placeholder="Select"
            value={state.maxTasks}
            options={[
                {
                    value: 100,
                    label: '100 tasks max',
                },
                {
                    value: 200,
                    label: '200 tasks max',
                },
                {
                    value: 300,
                    label: '300 tasks max',
                },
                {
                    value: 3000,
                    label: 'All tasks',
                },
            ]}
        />
    </Flex>
);

export default ButtonToolbar;

export function clearTaskFilters() {
    if (document.getElementById('task_title_filter')) {
        document.getElementById('task_title_filter').value = null;
    }
    if (document.getElementById('stat-due-date')) {
        document.getElementById('stat-due-date').value = null;
    }
    if (document.getElementById('internal-due-date')) {
        document.getElementById('internal-due-date').value = null;
    }

    window.localStorage.setItem('searchByTitle', '');
    window.localStorage.setItem('clientSelected', '');
    window.localStorage.setItem('partners', '');
    window.localStorage.setItem('reviewers', '');
    window.localStorage.setItem('assignedTo', '');
    window.localStorage.setItem('priority', '');
    window.localStorage.setItem('serviceFilter', '');
    window.localStorage.setItem('statuses', '');
    window.localStorage.setItem('myTasksFilter', '');
    window.localStorage.setItem(
        'maxTasks',
        JSON.stringify({
            value: 100,
            label: '100 tasks max',
        })
    );
    window.localStorage.setItem('columns', JSON.stringify(defaultColumnOptions));
}
