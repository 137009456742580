import moment from 'moment';
import { Flex, Paragraph } from 'theme-ui';
import { deleteDocument, downloadDocument } from './api';

const Attachment = ({ updateState, state, refreshCallback, data }) => (
    <Flex
        sx={{
            mt: 30,
            width: 400,
            flexDirection: 'column',
        }}
    >
        <Flex sx={{ justifyContent: 'space-between', fontSize: 14 }}>
            <Paragraph>{data.file_name}</Paragraph>

            <Flex sx={{ alignSelf: 'flex-end', cursor: 'pointer' }}>
                <i
                    style={{
                        marginTop: '0px',
                        fontSize: '12px',
                        marginLeft: '9px',
                        marginRight: '6px',
                    }}
                    className={`fas fa-download`}
                    onClick={async () => {
                        await downloadDocument(data.id, state, updateState);
                    }}
                />
                <i
                    style={{
                        marginTop: '0px',
                        fontSize: '12px',
                        marginLeft: '9px',
                        marginRight: '6px',
                        color: 'red',
                    }}
                    className={`fal fa-trash`}
                    onClick={() =>
                        updateState({
                            ...state,
                            showDeleteDocumentConfirmation: true,
                            confirmCallback: deleteDocument(data.id, state, updateState, refreshCallback),
                        })
                    }
                />
            </Flex>
        </Flex>

        <Paragraph sx={{ mt: 20, alignSelf: 'flex-end', fontSize: 12 }}>
            {moment.utc(data.created_at).format('HH:mm Do MMM YY')}
        </Paragraph>
        <Flex sx={{ mt: 20, borderBottom: '1px solid #CCC', height: '2px', width: '100%' }} />
    </Flex>
);

export default Attachment;
