/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-undef */
import axios from 'axios';
import ReactSelect from 'react-select';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Checkbox, Flex, Image, Input, Label, Paragraph } from 'theme-ui';
import theme from '../../theme';
import Divider from '../divider';

const EditableRow = ({
    localState,
    updateLocalState,
    label,
    type,
    attributeKey,
    width = 200,
    maxLength,
    showImagePreview,
    height = 40,
}) => {
    return (
        <Flex sx={{ flexDirection: 'column', mb: 20 }}>
            <Paragraph sx={{ mb: 10 }}>{label}</Paragraph>
            {type !== 'textarea' && (
                <Input
                    sx={{ width, height }}
                    onChange={(e) => updateLocalState({ ...localState, [attributeKey]: e.target.value })}
                    value={localState[attributeKey]}
                    type={type}
                    maxLength={maxLength}
                />
            )}
            {type === 'textarea' && (
                <ReactQuill
                    theme="snow"
                    value={localState[attributeKey]}
                    style={{ height: 300, width: width || 560, marginBottom: 30 }}
                    onChange={(val) =>
                        updateLocalState({
                            ...localState,
                            [attributeKey]: val,
                        })
                    }
                />
            )}
            {showImagePreview && <Image sx={{ width: 300, mb: 10, mt: 40 }} src={localState[attributeKey]} />}
        </Flex>
    );
};

const previewFile = (file) => {
    if (!file) return;
    const reader = new FileReader();

    reader.onload = function (e) {
        const img = document.getElementById('preview-img');
        img.src = e.target.result;
    };

    reader.readAsDataURL(file);
};

const moveObject = (direction, arr, index) => {
    // Ensure the index is within valid bounds
    if (direction === 'up' && index > 0) {
        // Move the object up (swap with the previous item)
        [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];
    } else if (direction === 'down' && index < arr.length - 1) {
        // Move the object down (swap with the next item)
        [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
    }
    return arr;
};

const InvoiceSettings = ({ organisation, state, updateState }) => {
    const [localState, updateLocalState] = useState({
        defaultIncrements: organisation?.default_visualisation?.timesheets?.defaultIncrements || 15,
        ...organisation?.default_visualisation?.invoiceOptions,
        customFields: organisation?.default_visualisation?.invoiceOptions?.customFields || [],
        customServiceOptions: organisation?.default_visualisation?.invoiceOptions?.customServiceOptions || [],
        tableHeaderColor: organisation?.default_visualisation?.invoiceOptions?.tableHeaderColor || theme.colors.primary,
        cc: (organisation?.default_visualisation?.invoiceOptions?.cc || []).join(', '),
        bcc: (organisation?.default_visualisation?.invoiceOptions?.bcc || []).join(', '),
        ...organisation?.default_visualisation?.address,
        invoicePdfLogo:
            organisation?.default_visualisation?.invoicePdfLogo || organisation?.default_visualisation?.logo,
    });

    useEffect(() => {
        if (localState.invoicePdfLogo) {
            document.getElementById('preview-img').src = localState.invoicePdfLogo;
        }
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Flex sx={{ mt: 0, width: 900, justifyContent: 'space-between' }}>
                <Paragraph sx={{ color: 'text', fontWeight: 600, mt: 10, fontSize: 20 }}>Invoice Settings</Paragraph>
            </Flex>
            <Divider width="900px" />
            <Paragraph sx={{ fontSize: 18, mb: 20 }}>General Settings</Paragraph>
            <Flex sx={{ alignItems: 'center', mb: 10 }}>
                <Label
                    sx={{
                        display: 'inline-block',
                        padding: '10px 20px',
                        color: '#fff',
                        backgroundColor: 'primary',
                        borderRadius: '15px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        width: 260,
                        height: 40,
                        '&:hover': {
                            backgroundColor: '#EFEFEF',
                            color: '#000',
                        },
                    }}
                >
                    <i
                        style={{
                            fontSize: '18px',
                            cursor: 'pointer',
                            marginRight: '10px',
                        }}
                        className="fas fa-file"
                        aria-hidden="true"
                    />
                    Upload logo for invoice
                    <Input
                        id="ref_file_input"
                        sx={{
                            display: 'none',
                        }}
                        accept=".png, .jpeg, .jpg"
                        type="file"
                        onChange={async (e) => {
                            updateLocalState({ ...localState, invoicePdfLogo: e.target.files?.[0] });
                            previewFile(e.target.files?.[0]);
                        }}
                    />
                </Label>
                {localState.invoicePdfLogo ? (
                    <Image sx={{ width: 150, ml: 30 }} alt="Preview" id="preview-img" />
                ) : null}
            </Flex>
            <EditableRow
                label="Default time recording increments (minutes)"
                attributeKey="defaultIncrements"
                type="number"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Due Date Days From Today"
                attributeKey="dueDateDays"
                type="number"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Invoice PDF Table Header Color"
                attributeKey="tableHeaderColor"
                type="color"
                width={50}
                height={50}
                localState={localState}
                updateLocalState={updateLocalState}
            />

            <Divider width="900px" />
            <Paragraph sx={{ fontSize: 18, mb: 20 }}>Company Address</Paragraph>
            <EditableRow
                label="Company address Line 1"
                attributeKey="addressLineOne"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company address city"
                attributeKey="locality"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company address post code"
                attributeKey="postalCode"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company address country"
                attributeKey="country"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company vat number"
                attributeKey="vatNumber"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Divider width="900px" />
            <Paragraph sx={{ fontSize: 18, mb: 20 }}>Custom Fields</Paragraph>
            {localState.customFields?.length ? (
                <table style={{ borderCollapse: 'collapse', width: 1400 }}>
                    <thead style={{ backgroundColor: theme.colors.primary, color: 'white' }}>
                        <th style={{ padding: 15 }}>Label</th>
                        <th style={{ padding: 15 }}>Default value (optional)</th>
                        <th style={{ padding: 15 }}>Type</th>
                        <th style={{ padding: 15 }}>Editable</th>
                        <th style={{ padding: 15 }}></th>
                        <th style={{ padding: 15 }}></th>
                        <th style={{ padding: 15 }}></th>
                    </thead>
                    {localState.customFields?.map((field, idx) => (
                        <tr key={`custom_field_${idx}`}>
                            <td style={{ padding: 10, width: 400 }}>
                                <Input
                                    onChange={(e) => {
                                        updateLocalState({
                                            ...localState,
                                            customFields: localState.customFields?.map((field, x) => {
                                                if (x === idx) {
                                                    field.label = e.target.value;
                                                }
                                                return field;
                                            }),
                                        });
                                    }}
                                    placeholder="Enter a label"
                                    value={field.label}
                                    type="text"
                                />
                            </td>
                            <td style={{ padding: 10, width: 400 }}>
                                <Input
                                    onChange={(e) => {
                                        updateLocalState({
                                            ...localState,
                                            customFields: localState.customFields?.map((field, x) => {
                                                if (x === idx) {
                                                    field.defaultValue = e.target.value;
                                                }
                                                return field;
                                            }),
                                        });
                                    }}
                                    placeholder="Enter a default value"
                                    value={field.defaultValue}
                                    type="text"
                                />
                            </td>
                            <td style={{ padding: 10, width: 150 }}>
                                <ReactSelect
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                            minHeight: '40px',
                                            marginTop: '10px',
                                            borderRadius: 10,
                                            marginBottom: '10px',
                                            border: '1px solid #a3a69b',
                                            fontSize: '14px',
                                        }),
                                    }}
                                    onChange={(value) => {
                                        updateLocalState({
                                            ...localState,
                                            customFields: localState.customFields?.map((field, x) => {
                                                if (x === idx) {
                                                    field.type = value;
                                                }
                                                return field;
                                            }),
                                        });
                                    }}
                                    placeholder={'Select the type of field'}
                                    value={field.type}
                                    options={[
                                        { value: 'text', label: 'Text' },
                                        { value: 'text_area', label: 'Text area' },
                                        { value: 'number', label: 'Number' },
                                        { value: 'date', label: 'Date' },
                                    ]}
                                />
                            </td>
                            <td style={{ padding: 10 }}>
                                <Flex
                                    onClick={(e) => {
                                        updateLocalState({
                                            ...localState,
                                            customFields: localState.customFields?.map((field, x) => {
                                                if (x === idx) {
                                                    field.editable = !!field.editable ? false : true;
                                                }
                                                return field;
                                            }),
                                        });
                                    }}
                                    sx={{ ml: 25 }}
                                >
                                    <Checkbox checked={field.editable} />
                                </Flex>
                            </td>
                            <td style={{ padding: 10 }}>
                                {idx > 0 ? (
                                    <i
                                        style={{
                                            fontSize: '16px',
                                            cursor: 'pointer',
                                            color: '#000',
                                            marginLeft: 20,
                                        }}
                                        className="fas fa-arrow-up"
                                        aria-hidden="true"
                                        onClick={async () => {
                                            updateLocalState({
                                                ...localState,
                                                customFields: moveObject('up', localState.customFields, idx),
                                            });
                                        }}
                                    />
                                ) : null}
                            </td>

                            <td style={{ padding: 10 }}>
                                {idx < localState.customFields.length - 1 ? (
                                    <i
                                        style={{
                                            cursor: 'pointer',
                                            color: '#000',
                                            fontSize: '16px',
                                            marginLeft: 20,
                                        }}
                                        className="fas fa-arrow-down"
                                        aria-hidden="true"
                                        onClick={async () => {
                                            updateLocalState({
                                                ...localState,
                                                customFields: moveObject('down', localState.customFields, idx),
                                            });
                                        }}
                                    />
                                ) : null}
                            </td>
                            <td style={{ padding: 10 }}>
                                <Button
                                    variant="light"
                                    onClick={() =>
                                        updateLocalState({
                                            ...localState,
                                            customFields: localState.customFields?.filter((field, x) => {
                                                return x !== idx;
                                            }),
                                        })
                                    }
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        color: 'text',
                                        height: 40,
                                        textAlign: 'center',
                                    }}
                                >
                                    <i
                                        style={{
                                            marginRight: '7px',
                                            color: 'red',
                                        }}
                                        className={`fal fa-trash`}
                                    />
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </table>
            ) : null}
            <Button
                onClick={() => {
                    updateLocalState({
                        ...localState,
                        customFields: [
                            ...(localState.customFields || []),
                            { label: '', type: { value: 'text', label: 'Text' } },
                        ],
                    });
                }}
                variant="light"
                sx={{ mt: 10, width: 100 }}
            >
                <i
                    style={{
                        marginRight: '7px',
                    }}
                    className={`fas fa-plus-circle`}
                />
                Add
            </Button>
            <Divider width="900px" />
            <Paragraph sx={{ fontSize: 18, mb: 20 }}>Custom Service Options</Paragraph>
            {localState.customServiceOptions?.length ? (
                <table style={{ borderCollapse: 'collapse', width: 720 }}>
                    <thead style={{ backgroundColor: theme.colors.primary, color: 'white' }}>
                        <th style={{ padding: 15 }}>Label</th>
                        <th style={{ padding: 15 }}></th>
                    </thead>
                    {localState.customServiceOptions?.map((field, idx) => (
                        <tr key={`custom_field_${idx}`}>
                            <td style={{ padding: 10, width: 400 }}>
                                <Input
                                    onChange={(e) => {
                                        updateLocalState({
                                            ...localState,
                                            customServiceOptions: localState.customServiceOptions?.map((field, x) => {
                                                if (x === idx) {
                                                    field.label = e.target.value;
                                                }
                                                return field;
                                            }),
                                        });
                                    }}
                                    placeholder="Enter a label"
                                    value={field.label}
                                    type="text"
                                />
                            </td>
                            <td style={{ padding: 10 }}>
                                <Button
                                    variant="light"
                                    onClick={() =>
                                        updateLocalState({
                                            ...localState,
                                            customServiceOptions: localState.customServiceOptions?.filter(
                                                (field, x) => {
                                                    return x !== idx;
                                                }
                                            ),
                                        })
                                    }
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        color: 'text',
                                        height: 40,
                                        textAlign: 'center',
                                    }}
                                >
                                    <i
                                        style={{
                                            marginRight: '7px',
                                            color: 'red',
                                        }}
                                        className={`fal fa-trash`}
                                    />
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </table>
            ) : null}
            <Button
                onClick={() => {
                    updateLocalState({
                        ...localState,
                        customServiceOptions: [...(localState.customServiceOptions || []), { label: '' }],
                    });
                }}
                variant="light"
                sx={{ mt: 10, width: 100 }}
            >
                <i
                    style={{
                        marginRight: '7px',
                    }}
                    className={`fas fa-plus-circle`}
                />
                Add
            </Button>
            <Divider width="900px" />
            <Paragraph sx={{ fontSize: 18, mb: 20 }}>Email Settings</Paragraph>
            <EditableRow
                label="Invoice Email Cc (comma separated)"
                attributeKey="cc"
                type="text"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Invoice Email Bcc (comma separated)"
                attributeKey="bcc"
                type="text"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />

            <Paragraph>The following tokens are available in subject lines and emails</Paragraph>

            <ul style={{ fontSize: 14 }}>
                <li>{'{invoiceNumber}'}</li>
                <li>{'{dueDate}'}</li>
                <li>{'{clientName}'}</li>
                <li>{'{totalDue}'}</li>
            </ul>

            <EditableRow
                label="Invoice Email Default Subject Line"
                attributeKey="defaultSubjectLine"
                type="text"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Invoice Email Default Body"
                attributeKey="defaultInvoiceBody"
                type="textarea"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />

            <Divider width="900px" />
            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Button
                    onClick={async () => {
                        await saveInvoiceOptions(state, updateState, localState, updateLocalState);
                    }}
                    disabled={organisation?.user?.role !== 'admin'}
                    sx={{ ml: 20, width: 100 }}
                >
                    <i className="fal fa-save" style={{ marginRight: '7px' }} />
                    Save
                </Button>
            </Flex>
        </Flex>
    );
};

const saveInvoiceOptions = async (state, updateState, localState, updateLocalState) => {
    try {
        updateState({ ...state, loading: true });

        if (localState.invoicePdfLogo?.type) {
            const {
                data: { url },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/company-img`,
                method: 'POST',
                data: {
                    content_type: localState.invoicePdfLogo.type,
                    image_key: 'invoicePdfLogo',
                    base_url: `https://aflow-avatars-${
                        process.env.REACT_APP_IS_LIVE === '1' ? 'prod' : 'uat'
                    }.s3.eu-west-1.amazonaws.com/`,
                },
            });

            await axios.put(url, localState.invoicePdfLogo, {
                headers: { 'Content-Type': localState.invoicePdfLogo.type },
            });
        }

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                default_visualisation: {
                    timesheets: {
                        defaultIncrements: localState.defaultIncrements,
                    },
                    invoiceOptions: {
                        dueDateDays: localState.dueDateDays,
                        cc: localState.cc?.split(',') || [],
                        bcc: localState.bcc?.split(',') || [],
                        defaultSubjectLine: localState.defaultSubjectLine,
                        defaultInvoiceBody: localState.defaultInvoiceBody,
                        tableHeaderColor: localState.tableHeaderColor,
                        customFields: localState.customFields || [],
                        customServiceOptions: localState.customServiceOptions || [],
                    },
                    address: {
                        addressLineOne: localState.addressLineOne,
                        locality: localState.locality,
                        postalCode: localState.postalCode,
                        country: localState.country,
                        vatNumber: localState.vatNumber,
                    },
                },
            },
        });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'GET',
        });

        updateState({ ...state, loading: false, organisation: data });

        toast('Successfully saved', {
            type: 'success',
            autoClose: 5000,
            position: 'top-right',
        });
    } catch (e) {
        console.log(e);

        updateState({ ...state, loading: false });
        toast('We are currently unable to save your settings', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

export default InvoiceSettings;
