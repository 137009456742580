/* eslint-disable no-undef */
import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Flex, Input, Label, Paragraph, Textarea } from 'theme-ui';
import { deepClone } from '../settings/manage-workflow';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';

const statusMapping = {
    todo: 'fa-square',
    done: 'fa-check-square',
};

const logActivity = (uuid, status, subtask_title) => {
    axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/tasks/log-subtask-activity`,
        data: {
            uuid,
            status,
            subtask_title,
        },
        method: 'POST',
    })
        .then(() => ({}))
        .catch(() => ({}));
};

const suggestEmailTemplate = async (state, updateState, subtaskState) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { templates },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates`,
            params: { limit: 500 },
        });

        const template = templates.find((x) => x.uuid === subtaskState.emailTemplateToSuggest);

        if (template) {
            let clientForEmailTemplate;
            if (state.fullTaskData?.client_for_component?.value) {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.fullTaskData?.client_for_component?.value}`,
                    params: {
                        raw_id: '1',
                    },
                });
                clientForEmailTemplate = data;

                clientForEmailTemplate.primary_contact = data?.client_data?.key_contacts?.find(
                    (x) => x.is_primary_contact
                );
            }

            updateState({
                ...state,
                loading: false,
                showTemplateSuggestionModal: true,
                template,
                clientForEmailTemplate,
            });
        } else {
            updateState({ ...state, loading: false });
        }
    } catch (e) {
        updateState({ ...state, loading: false });
    }
};

const Subtask = ({ subtask, state, updateState, idx, organisation }) => {
    const [subtaskState, updateSubtaskState] = useState({
        edit: subtask.edit || false,
        title: subtask.title,
        status: subtask.status || 'todo',
        uuid: subtask.uuid,
        uiKey: subtask.uiKey,
        suggestEmailTemplate: subtask.suggestEmailTemplate,
        emailTemplateToSuggest: subtask.emailTemplateToSuggest,
        task_completed_by: subtask.task_completed_by,
        task_completed_at: subtask.task_completed_at,
        creator: subtask.creator,
    });

    const missingPermissionToEdit = subtask.requireAdminPermission && organisation?.user.role !== 'admin';

    let completedByName = '';
    if (subtaskState.task_completed_by) {
        if (subtaskState.task_completed_by === 'System') {
            completedByName = 'System';
        } else if (subtaskState.task_completed_by === 'Accounting Flow AI') {
            completedByName = 'Accounting Flow AI';
        } else {
            const user = state.users?.find((x) => x.id === subtaskState.task_completed_by);
            if (user) {
                completedByName = `${user.first_name} ${user.last_name}`;
            }
        }
    }

    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'subtask',
            item: { subtask, idx },
            end: (item, monitor) => {
                if (state.idxMovingTo === state.idxOfItemMoving) return;

                const newSubtasks = deepClone([...(state.fullTaskData?.subtasks || [])]);

                const itemToMove = deepClone(newSubtasks[state.idxOfItemMoving]);
                const existingAtLocation = deepClone(newSubtasks[state.idxMovingTo]);

                newSubtasks[state.idxMovingTo] = itemToMove;
                newSubtasks[state.idxOfItemMoving] = existingAtLocation;

                updateState({
                    ...state,
                    fullTaskData: {
                        ...state.fullTaskData,
                        subtasks: newSubtasks,
                    },
                    idxMovingTo: -1,
                    idxOfItemMoving: -1,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving, subtask]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'subtask',
            hover(item) {
                updateState({
                    ...state,
                    idxOfItemMoving: idx,
                    idxMovingTo: item.idx,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving]
    );

    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div ref={(node) => drag(drop(node))} {...collected}>
            <Flex
                sx={{
                    padding: '15px 0px',
                    borderTop: '1px solid #EFEFEF',
                    cursor: 'pointer',
                    flexDirection: 'column',
                }}
            >
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        alignItems: 'center',
                    }}
                >
                    {!subtaskState.edit && (
                        <Paragraph
                            onClick={() =>
                                !subtask.autoResolveUsingWorkflow &&
                                updateSubtaskState({
                                    ...subtaskState,
                                    edit: true,
                                })
                            }
                            sx={{
                                width: 250,
                                fontSize: 14,
                                wordWrap: 'break-word',
                                textDecoration: subtaskState.status === 'done' ? 'line-through' : '',
                            }}
                        >
                            {subtaskState.title}
                        </Paragraph>
                    )}
                    {subtaskState.edit && (
                        <Textarea
                            value={subtaskState.title}
                            onChange={(e) => {
                                updateSubtaskState({
                                    ...subtaskState,
                                    title: e.target.value,
                                });
                                updateState({
                                    ...state,
                                    fullTaskData: {
                                        ...state.fullTaskData,
                                        subtasks: (state.fullTaskData.subtasks || []).map((x) => {
                                            if (x.uiKey === subtaskState.uiKey) {
                                                x.title = e.target.value;
                                            }
                                            return x;
                                        }),
                                    },
                                });
                            }}
                            sx={{ width: 350, fontWeight: '400', border: '1px solid #a3a69b', mb: 0 }}
                        />
                    )}
                    <Flex sx={{ alignItems: 'center' }}>
                        {subtask.video_url ? (
                            <Button
                                sx={{ ml: 10, mr: 10, height: 40 }}
                                variant="light"
                                onClick={async () => {
                                    const {
                                        data: { url },
                                    } = await axios.get(
                                        `${process.env.REACT_APP_AQRU_AI_API}/documents/${subtask.video_url}`,
                                        {}
                                    );
                                    updateState({
                                        ...state,
                                        showSubTaskHelpVideo: true,
                                        showSubTaskHelpVideoUrl: url,
                                    });
                                }}
                            >
                                <i
                                    style={{
                                        marginRight: '7px',
                                    }}
                                    className="fas fa-video"
                                />{' '}
                                Video
                            </Button>
                        ) : null}
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Label
                                sx={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    color: '#fff',
                                    backgroundColor: 'primary',
                                    borderRadius: '15px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    transition: 'background-color 0.3s',
                                    width: '160px',
                                    '&:hover': {
                                        backgroundColor: '#EFEFEF',
                                        color: '#000',
                                    },
                                }}
                            >
                                <i
                                    style={{
                                        cursor: 'pointer',
                                        marginRight: '10px',
                                    }}
                                    className="fas fa-video"
                                    aria-hidden="true"
                                />
                                Upload
                                <Input
                                    id="ref_file_input"
                                    sx={{
                                        display: 'none',
                                    }}
                                    accept=".mp4"
                                    type="file"
                                    onChange={async (e) => {
                                        try {
                                            updateState({ ...state, loading: true });

                                            const newFile = [...e.target.files]?.[0];

                                            const {
                                                data: { document_uuid, presigned_url },
                                            } = await axios({
                                                url: `${process.env.REACT_APP_AQRU_AI_API}/documents`,
                                                method: 'POST',
                                                data: {
                                                    content_type: newFile.type,
                                                    file_name: newFile.name,
                                                },
                                            });

                                            await axios.put(presigned_url, newFile, {
                                                headers: { 'Content-Type': newFile.type },
                                            });

                                            updateSubtaskState({
                                                ...subtaskState,
                                                video_url: document_uuid,
                                            });

                                            updateState({
                                                ...state,
                                                loading: false,
                                                fullTaskData: {
                                                    ...state.fullTaskData,
                                                    subtasks: (state.fullTaskData.subtasks || []).map((x) => {
                                                        if (x.uiKey === subtaskState.uiKey) {
                                                            x.video_url = document_uuid;
                                                        }
                                                        return x;
                                                    }),
                                                },
                                            });

                                            document.getElementById('ref_file_input').value = '';
                                        } catch (e) {
                                            console.log(e);
                                            updateState({
                                                ...state,
                                                loading: false,
                                            });
                                            toast.error('We are currently unable to upload your file');
                                        }
                                    }}
                                />
                            </Label>
                            {subtask.video_url ? (
                                <Button
                                    sx={{ mt: 10, height: 40 }}
                                    variant="light"
                                    onClick={async () => {
                                        updateSubtaskState({
                                            ...subtaskState,
                                            video_url: null,
                                        });

                                        updateState({
                                            ...state,
                                            fullTaskData: {
                                                ...state.fullTaskData,
                                                subtasks: (state.fullTaskData.subtasks || []).map((x) => {
                                                    if (x.uiKey === subtaskState.uiKey) {
                                                        x.video_url = null;
                                                    }
                                                    return x;
                                                }),
                                            },
                                        });
                                    }}
                                >
                                    <i
                                        style={{
                                            marginRight: '7px',
                                            color: 'red',
                                        }}
                                        className="fal fa-trash"
                                    />{' '}
                                    Remove video
                                </Button>
                            ) : null}
                        </Flex>

                        {!subtask.autoResolveUsingWorkflow && !missingPermissionToEdit && (
                            <Flex>
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '18px',
                                        marginLeft: '9px',
                                        marginRight: '6px',
                                        color: '#000',
                                    }}
                                    onClick={async () => {
                                        let extraState = { task_completed_at: null, task_completed_by: null };
                                        if (subtaskState.status === 'todo') {
                                            extraState = {
                                                task_completed_at: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                                                task_completed_by: state.userIdOfLoggedInUser,
                                            };
                                            logActivity(state.fullTaskData.id, 'completed', subtaskState.title);
                                        } else {
                                            logActivity(state.fullTaskData.id, 'opened', subtaskState.title);
                                        }

                                        updateSubtaskState({
                                            ...subtaskState,
                                            status: subtaskState.status === 'todo' ? 'done' : 'todo',
                                            edit: subtaskState.status === 'todo' ? false : true,
                                            ...extraState,
                                        });

                                        let changeTaskStatus = subtask.changeStatus && subtaskState.status === 'todo';

                                        // check if subtask after this index is already done
                                        if (changeTaskStatus) {
                                            for (let i = idx + 1; i < state.fullTaskData.subtasks.length; i++) {
                                                if (state.fullTaskData.subtasks[i].status === 'done') {
                                                    changeTaskStatus = false;
                                                    break;
                                                }
                                            }
                                        }

                                        updateState({
                                            ...state,
                                            fullTaskData: {
                                                ...state.fullTaskData,
                                                ...(changeTaskStatus
                                                    ? {
                                                          status_for_component: {
                                                              value: subtask.changeStatusTo,
                                                              label: organisation?.default_visualisation?.statuses?.find(
                                                                  (x) => x.key === subtask?.changeStatusTo
                                                              )?.label,
                                                          },
                                                      }
                                                    : {}),
                                                subtasks: (state.fullTaskData.subtasks || []).map((x) => {
                                                    if (x.uiKey === subtaskState.uiKey) {
                                                        x.status = subtaskState.status === 'todo' ? 'done' : 'todo';
                                                        x.edit = subtaskState.status === 'todo' ? false : true;

                                                        x.task_completed_at = extraState.task_completed_at;
                                                        x.task_completed_by = extraState.task_completed_by;
                                                    }
                                                    return x;
                                                }),
                                            },
                                        });

                                        if (subtaskState.status === 'todo' && subtaskState.suggestEmailTemplate) {
                                            await suggestEmailTemplate(state, updateState, subtaskState);
                                        }
                                    }}
                                    className={`fal ${statusMapping[subtaskState.status]}`}
                                />
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '18px',
                                        marginLeft: '9px',
                                        marginRight: '6px',
                                        color: 'red',
                                    }}
                                    className="fal fa-trash"
                                    onClick={() =>
                                        updateState({
                                            ...state,
                                            showDeleteSubtaskConfirmation: true,
                                            confirmCallback: () => {
                                                updateState({
                                                    ...state,
                                                    showDeleteSubtaskConfirmation: false,
                                                    fullTaskData: {
                                                        ...state.fullTaskData,
                                                        subtasks: (state.fullTaskData.subtasks || []).filter(
                                                            (x) => x.uiKey !== subtaskState.uiKey
                                                        ),
                                                    },
                                                });
                                            },
                                        })
                                    }
                                />
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <a
                        onClick={async () => {
                            updateState({
                                ...state,
                                showSubTaskCommentsDialog: true,
                                subTaskUIKey: subtaskState.uiKey,
                            });
                        }}
                        style={{ color: 'blue', fontSize: 13, marginTop: '10px' }}
                    >
                        Comments ({subtask.comments?.length || 0})
                    </a>
                    {subtaskState.suggestEmailTemplate && subtaskState.status === 'done' && (
                        <a
                            onClick={async () => {
                                await suggestEmailTemplate(state, updateState, subtaskState);
                            }}
                            style={{ color: 'blue', fontSize: 13, marginTop: '10px' }}
                        >
                            View Email Template
                        </a>
                    )}
                    {subtaskState.creator && (
                        <Flex>
                            <Paragraph sx={{ mt: 10, fontSize: 13 }}>Created by {subtaskState.creator}</Paragraph>
                        </Flex>
                    )}
                    {subtaskState.task_completed_at && subtaskState.status === 'done' && (
                        <Flex>
                            <Paragraph sx={{ mt: 10, fontSize: 13 }}>
                                Completed: {moment(subtaskState.task_completed_at).format('HH:mm Do MMM YY')}
                                {completedByName ? ` by ${completedByName}` : ''}
                            </Paragraph>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

export default Subtask;
