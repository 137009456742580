/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Paragraph, Flex, Box, Image, Button } from 'theme-ui';
import moment from 'moment';
import { useState } from 'react';
import { getSingleDefinition } from './documents/load-docs';
import { UploadDrilledDownView, UploadDrilledDownViewDoc } from './documents/uploads';

const RowWrapper = (props: any) => {
    if (props.href) {
        return (
            <a target={props.openInNewTab ? '_blank' : '_self'} style={{ textDecoration: 'none' }} href={props.href}>
                {props.children}
            </a>
        );
    }

    return props.children;
};

export const TableHeaderItem = ({
    text,
    textAlign = 'center',
    topLeft = false,
    topRight = false,
    processed = false,
    overrideSize = false,
    additionalStyles = {},
    onClick,
    sortDirection,
}: any) => {
    let additionalSx = processed ? { width: '120px' } : { flex: 1 };
    additionalSx = overrideSize ? { width: overrideSize } : additionalSx;
    return (
        <Box
            onClick={onClick}
            sx={{
                textAlign,
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '45px',
                padding: '0 10px 0 15px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: '#f9fafb',
                color: '#495465	',
                ...additionalSx,
                ...additionalStyles,
            }}
        >
            {text}
            {sortDirection && sortDirection === 'ASC' && (
                <i style={{ marginLeft: 10 }} className="far fa-chevron-down" />
            )}
            {sortDirection && sortDirection === 'DESC' && (
                <i style={{ marginLeft: 10 }} className="far fa-chevron-up" />
            )}
        </Box>
    );
};

export const TableHeader = ({ isMobile = false }) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
            color: '#fff',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        }}
    >
        {!isMobile && <TableHeaderItem text="" topLeft />}
        <TableHeaderItem text="Date" topLeft={window.innerWidth <= 600} />
        <TableHeaderItem text="Currency" />
        <TableHeaderItem text="Amount" />
        <TableHeaderItem text="Type" topRight />
    </Flex>
);

export const TableRowItem = ({
    text,
    textAlign = 'center',
    action = () => {},
    type = '',
    expandTypeText = false,
    openInNewTab = false,
    href,
    image = false,
    docUploads = false,
    processedStatus = null,
    hideText = false,
    actions = false,
    overrideSize = false,
    item = {},
    navigate,
    scrollLatestIntoView,
    state,
    updateState,
    deleteFile,
    additionalStyles = {},
    showLinkIcon = false,
    cursorToUse = false,
    showHideIcons = false,
    showHideValue = false,
}: any) => {
    const fontSize = '13px';
    let additionalSx = processedStatus ? { width: '120px' } : { flex: 1 };
    additionalSx = overrideSize ? { width: overrideSize } : additionalSx;

    return (
        <Flex
            sx={{
                borderBottom: '1px solid #eaecf0',
                textAlign: 'center',
                lineHeight: '20px',
                padding: '20px',
                background: '#FFF',
                width: '90%',
                justifyContent: 'center',
                position: 'relative',
                textTransform: 'capitalize',
                minHeight: [100, '70px'],
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderRight: '1px solid #dddddd',
                cursor: cursorToUse ? cursorToUse : type === 'view' || expandTypeText ? 'pointer' : 'default',
                ...additionalSx,
            }}
            onClick={() => {
                if (!expandTypeText) {
                    action();
                }
            }}
        >
            <RowWrapper openInNewTab={openInNewTab} href={href}>
                {image && !docUploads && (
                    <Box sx={{ width: [25, '40px'], height: [25, '40px'] }}>
                        <Image src={text} width="30px" height="30px" alt="icon" />
                    </Box>
                )}

                {!image && !docUploads && !hideText && (
                    <Flex sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                        <Paragraph
                            sx={{
                                color: '#002033',
                                fontSize,
                                fontWeight: 400,
                                textAlign,
                                position: 'relative',
                                ...additionalStyles,
                            }}
                        >
                            {text}
                        </Paragraph>
                        {showHideIcons && (
                            <Paragraph
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    ml: '20px',
                                }}
                            >
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '15px',
                                    }} // @ts-ignore
                                    className={`fas fa-chevron-${showHideValue ? 'down' : 'right'}`}
                                />
                            </Paragraph>
                        )}
                        {showLinkIcon && (
                            <Paragraph
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    ml: '40px',
                                }}
                            >
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '12px',
                                    }} // @ts-ignore
                                    className={`fas fa-external-link`}
                                />
                            </Paragraph>
                        )}
                    </Flex>
                )}

                {docUploads && (
                    <Flex
                        sx={{
                            mt: '5px',
                            justifyContent: 'space-around',
                            width: item.document_uploads?.length * 25,
                            color: 'primary',
                        }}
                    >
                        {item.document_uploads?.find((y: any) => y.file_extension === 'pdf') && (
                            <i
                                style={{
                                    fontSize: '20px',
                                }}
                                className="fas fa-file-pdf"
                            />
                        )}
                        {item.document_uploads?.find((y: any) => y.file_extension === 'docx') && (
                            <i
                                style={{
                                    fontSize: '20px',
                                }}
                                className="fas fa-file-word"
                            />
                        )}
                        {item.document_uploads?.find((y: any) => y.file_extension === 'txt') && (
                            <i
                                style={{
                                    fontSize: '20px',
                                }}
                                className="fas fa-text"
                            />
                        )}
                        {item.document_uploads?.find((y: any) => y.file_extension === 'mp3') && (
                            <i
                                style={{
                                    fontSize: '20px',
                                }}
                                className="fas fa-file-audio"
                            />
                        )}
                    </Flex>
                )}

                {processedStatus && (
                    <Box
                        sx={{
                            fontSize: '20px',
                            marginTop: '0px',
                            color: `${
                                processedStatus == 'pending' ||
                                processedStatus == 'PENDING' ||
                                processedStatus == 'PROCESSING' ||
                                processedStatus == 'AWAITING_UPLOAD' ||
                                processedStatus == 'READY_FOR_ANALYSIS'
                                    ? 'orange'
                                    : processedStatus === 'FINALISED' ||
                                      processedStatus === 'complete' ||
                                      processedStatus === 'PROCESSED'
                                    ? 'green'
                                    : 'red'
                            }`,
                        }}
                        className={`${
                            processedStatus == 'pending' ||
                            processedStatus == 'PENDING' ||
                            processedStatus == 'PROCESSING' ||
                            processedStatus == 'AWAITING_UPLOAD' ||
                            processedStatus == 'READY_FOR_ANALYSIS'
                                ? 'far fa-circle'
                                : processedStatus === 'FINALISED' ||
                                  processedStatus === 'complete' ||
                                  processedStatus === 'PROCESSED'
                                ? 'far fa-check-circle'
                                : 'far fa-times-circle'
                        }`}
                    />
                )}

                {actions && (
                    <Flex
                        sx={{
                            justifyContent: 'center',
                            flex: 1,
                        }}
                    >
                        {actions?.type === 'edit' && (
                            <Button
                                sx={{
                                    width: '70px',
                                    height: '40px',
                                    fontSize: '13px',
                                    border: '0px',
                                    mt: '-5px',
                                    background: 'white',
                                    color: item.processing_status === 'ERROR' ? '#CCC' : '#444',
                                    cursor: item.processing_status === 'ERROR' ? 'not-allowed !important' : 'cursor',
                                }}
                                disabled={item.processing_status !== 'PROCESSED'}
                                onClick={() => {
                                    navigate(`/templates?id=${item?.id}`);
                                }}
                            >
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '17px',
                                    }}
                                    className={`fas fa-file-plus`}
                                />
                                <br />
                            </Button>
                        )}

                        {actions?.type === 'query' && (
                            <Button
                                sx={{
                                    width: '70px',
                                    height: '40px',
                                    fontSize: '13px',
                                    border: '0px',
                                    mt: '-5px',
                                    background: 'white',
                                    color: item.processing_status === 'ERROR' ? '#CCC' : '#444',
                                    cursor: item.processing_status === 'ERROR' ? 'not-allowed !important' : 'cursor',
                                }}
                                disabled={item.processing_status !== 'PROCESSED'}
                                onClick={() => {
                                    updateState({
                                        ...state,
                                        mode: 'query',
                                        documentToQuery: item,
                                    });
                                    scrollLatestIntoView();
                                }}
                            >
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '17px',
                                    }}
                                    className={`fas fa-search`}
                                />
                                <br />
                            </Button>
                        )}

                        {actions?.type === 'delete' && (
                            <Button
                                sx={{
                                    width: '70px',
                                    height: '40px',
                                    fontSize: '13px',
                                    backgroundColor: '#FFF',
                                    mt: '-5px',

                                    color: item.processing_status === 'PROCESSED' ? 'primary' : '#444',
                                    border: '0px',
                                }}
                                onClick={() => deleteFile(state, updateState, item.id)}
                            >
                                <i
                                    style={{
                                        marginTop: '0px',
                                        fontSize: '17px',
                                    }}
                                    className={`fas fa-times-circle`}
                                />
                                <br />
                            </Button>
                        )}
                    </Flex>
                )}
            </RowWrapper>
        </Flex>
    );
};

export const TableRow = ({ item, i, isMobile }: any) => (
    <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', height: [120, '70px'] }}>
        {!isMobile && <TableRowItem text={item.type_img} image />}
        <TableRowItem text={moment(item.created_at).format('DD MMM YYYY') || ''} />
        <TableRowItem text={item.label} />
        <TableRowItem text={new Intl.NumberFormat('en-US', { maximumFractionDigits: 8 }).format(item.amount)} />
        <TableRowItem text={item.type_label} />
    </Flex>
);

export const TableHeaderUploads = ({ isMobile = false }) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '50px',
            color: '#fff',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        }}
    >
        {/* {!isMobile && <TableHeaderItem text="" topLeft />} */}
        <TableHeaderItem text="Description" topLeft textAlign="left" />
        <TableHeaderItem text="File Types Uploaded" overrideSize="230px" />
        <TableHeaderItem text="Status" processed />
        <TableHeaderItem text="Add" overrideSize="95px" />
        <TableHeaderItem text="Query" overrideSize="95px" />
        <TableHeaderItem text="Delete" overrideSize="95px" topRight />
    </Flex>
);

export const TableRowUploads = ({
    item,
    i,
    isMobile,
    navigate,
    scrollLatestIntoView,
    state,
    updateState,
    deleteFile,
}: any) => (
    <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', minHeight: [120, '70px'] }}>
        {/* {!isMobile && <TableRowItem text={item.type_img} image />} */}
        <TableRowItem text={item?.description} textAlign="left" />
        <TableRowItem docUploads item={item} text={item?.document_uploads} overrideSize="230px" />
        <TableRowItem processedStatus item={item} text={item?.processing_status} hideText />
        <TableRowItem
            actions={{ type: 'edit' }}
            item={item}
            text={item.type_label}
            hideText
            navigate={navigate}
            scrollLatestIntoView={scrollLatestIntoView}
            state={state}
            updateState={updateState}
            deleteFile={deleteFile}
            overrideSize="95px"
        />
        <TableRowItem
            actions={{ type: 'query' }}
            item={item}
            text={item.type_label}
            hideText
            navigate={navigate}
            scrollLatestIntoView={scrollLatestIntoView}
            state={state}
            updateState={updateState}
            deleteFile={deleteFile}
            overrideSize="95px"
        />
        <TableRowItem
            actions={{ type: 'delete' }}
            item={item}
            text={item.type_label}
            hideText
            navigate={navigate}
            scrollLatestIntoView={scrollLatestIntoView}
            state={state}
            updateState={updateState}
            deleteFile={deleteFile}
            overrideSize="95px"
        />
    </Flex>
);

//  DOC + UPLOADS LIST
export const TableHeaderDocList = ({ isMobile = false }) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '50px',
            color: '#fff',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        }}
    >
        <TableHeaderItem text="Templates" topLeft={window.innerWidth <= 600} textAlign="left" />
        <TableHeaderItem text="Status" processed topRight />
    </Flex>
);

export const TableRowDocList = ({
    item,
    i,
    isMobile,
    navigate,
    scrollLatestIntoView,
    state,
    updateState,
    deleteFile,
}: any) => {
    const [showDetail, updateDetail] = useState(false);
    const processedStatus = item?.document_templates[0]?.status || item?.processing_status;
    return (
        <Flex key={i} sx={{ width: '100%', minHeight: [120, '70px'], flexDirection: 'column' }}>
            <Flex
                sx={{ justifyContent: 'space-between', width: '100%', minHeight: [120, '70px'] }}
                onClick={() => updateDetail(!showDetail)}
            >
                <TableRowItem
                    text={`${item?.description}`}
                    showHideIcons
                    showHideValue={showDetail}
                    textAlign="left"
                    additionalStyles={{ fontSize: '14px' }}
                    cursorToUse="pointer"
                />
                <TableRowItem
                    processedStatus={processedStatus}
                    item={item}
                    text={item?.processing_status}
                    hideText
                    cursorToUse="pointer"
                />
            </Flex>
            {showDetail && <UploadDrilledDownView item={item} />}
        </Flex>
    );
};

// DOC ONLY
export const TableHeaderDocOnlyList = ({ isMobile = false }) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '50px',
            color: '#fff',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        }}
    >
        <TableHeaderItem text="Files" topLeft textAlign="left" />
        <TableHeaderItem text="Status" processed topRight />
    </Flex>
);

export const TableRowDocOnlyList = ({
    item,
    i,
    isMobile,
    navigate,
    scrollLatestIntoView,
    state,
    updateState,
    deleteFile,
}: any) => {
    const DocId = item?.id;
    const fileName = item?.file_name || item?.description || 'name unavailable';
    const showLinkIcon = item?.processing_status === 'PROCESSED';
    const cursorToUse = showLinkIcon ? 'pointer ' : 'not-allowed ';
    const processedStatus = item?.processing_status;
    const [showDetail, updateDetail] = useState(false);

    return (
        <Flex key={i} sx={{ width: '100%', minHeight: [120, '70px'], flexDirection: 'column' }}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    minHeight: [120, '70px'],
                }}
                onClick={() => updateDetail(!showDetail)}
            >
                <TableRowItem
                    showHideIcons
                    showHideValue={showDetail}
                    text={fileName}
                    textAlign="left"
                    additionalStyles={{ fontSize: '14px' }}
                    cursorToUse={cursorToUse}
                />
                <TableRowItem processedStatus={processedStatus} item={item} text={item?.processing_status} hideText />
            </Flex>
            {showDetail && <UploadDrilledDownViewDoc item={item} state={state} updateState={updateState} />}
        </Flex>
    );
};

// DEFINITIONS
export const TableHeaderDefinitions = ({ isMobile = false }) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '50px',
            color: '#fff',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        }}
    >
        <TableHeaderItem text="Drafts" topLeft={window.innerWidth <= 600} textAlign="left" />
        <TableHeaderItem text="Status" processed topRight />
    </Flex>
);

export const TableRowDefinitions = ({ item, i, isMobile, navigate, state, updateState }: any) => {
    const processedStatus = item?.status;
    return (
        <Flex key={i} sx={{ width: '100%', minHeight: [120, '70px'], flexDirection: 'column' }}>
            <Flex
                sx={{ justifyContent: 'space-between', width: '100%', minHeight: [120, '70px'] }}
                onClick={async () => {
                    updateState({
                        ...state,
                        loading: true,
                    });
                    const singleDef = await getSingleDefinition(item?.id);
                    console.log(singleDef);

                    updateState({
                        ...state,
                        loading: false,
                        mode: 'edit',
                        definitionId: item?.id,
                        definitionObjectInView: singleDef,
                    });
                }}
            >
                <TableRowItem
                    text={`${item?.description}`}
                    textAlign="left"
                    additionalStyles={{ fontSize: '14px' }}
                    cursorToUse="pointer"
                />
                <TableRowItem
                    processedStatus={processedStatus}
                    item={item}
                    text={item?.processing_status}
                    hideText
                    cursorToUse="pointer"
                />
            </Flex>
        </Flex>
    );
};

// CLIENTS
export const TableHeaderClients = ({ state, updateState }: any) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '50px',
            color: '#fff',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        }}
    >
        <TableHeaderItem
            text="Name"
            topLeft={window.innerWidth <= 600}
            textAlign="left"
            additionalStyles={{ cursor: 'pointer' }}
            // sortDirection={
            //     state.sortByKeys?.indexOf('name') === 0
            //         ? state.sortByDirections[state.sortByKeys.indexOf('name')]
            //         : null
            // }
            // onClick={() => {
            //     const direction = state.sortByDirections[state.sortByKeys.indexOf('name')] === 'ASC' ? 'DESC' : 'ASC';
            //     updateState({
            //         ...state,
            //         sortByDirections: [direction, 'ASC'],
            //         sortByKeys: ['name', 'internalReferenceCode'],
            //     });
            // }}
        />
        <TableHeaderItem
            text="Type"
            textAlign="left"
            additionalStyles={{ paddingLeft: 140, cursor: 'pointer' }}
            // sortDirection={
            //     state.sortByKeys?.indexOf('type') === 0
            //         ? state.sortByDirections[state.sortByKeys.indexOf('type')]
            //         : null
            // }
            // onClick={() => {
            //     const direction = state.sortByDirections[state.sortByKeys.indexOf('type')] === 'ASC' ? 'DESC' : 'ASC';
            //     updateState({ ...state, sortByDirections: [direction, 'ASC'], sortByKeys: ['type', 'name'] });
            // }}
        />
        <TableHeaderItem
            text="Client Code"
            textAlign="left"
            additionalStyles={{ paddingLeft: 140, cursor: 'pointer' }}
            // sortDirection={
            //     state.sortByKeys?.indexOf('internalReferenceCode') === 0
            //         ? state.sortByDirections[state.sortByKeys.indexOf('internalReferenceCode')]
            //         : null
            // }
            // onClick={() => {
            //     const direction =
            //         state.sortByDirections[state.sortByKeys.indexOf('internalReferenceCode')] === 'ASC'
            //             ? 'DESC'
            //             : 'ASC';
            //     updateState({
            //         ...state,
            //         sortByDirections: [direction, 'ASC'],
            //         sortByKeys: ['internalReferenceCode', 'name'],
            //     });
            // }}
        />
        <TableHeaderItem text="" processed topRight />
    </Flex>
);

export const TableRowClients = ({ item, i, isMobile, navigate, state, updateState }: any) => {
    return (
        <tr
            style={{
                cursor: 'pointer',
                fontSize: 14,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                height: 60,
            }}
        >
            {state.columns?.includes('name') && (
                <td
                    title={'Name'}
                    style={{
                        minHeight: 50,
                        width: 300,
                        textAlign: 'left',
                        padding: 20,
                        wordWrap: 'break-word',
                    }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item.is_password_protected ? (
                            <i style={{ fontSize: 14, marginRight: '7px', fontWeight: 300 }} className="fas fa-lock" />
                        ) : (
                            ''
                        )}
                        {`${item?.name}${item.archived ? ' - ARCHIVED' : ''}`}{' '}
                    </a>
                </td>
            )}
            {state.columns?.includes('type') && (
                <td
                    title={item.type === 'company' ? 'Company' : 'Individual'}
                    style={{ minHeight: 50, width: 100, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        <i
                            title={item.type === 'company' ? 'Company' : 'Individual'}
                            style={{
                                marginTop: '0px',
                                marginLeft: 10,
                                fontSize: '15px',
                            }} // @ts-ignore
                            className={`fas ${item.type === 'company' ? 'fa-building' : 'fa-user'}`}
                        />
                    </a>
                </td>
            )}
            {state.columns?.includes('internalReferenceCode') && (
                <td
                    title={'Client Code'}
                    style={{ minHeight: 50, width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.internal_reference_code}
                    </a>
                </td>
            )}
            {state.columns?.includes('company_number') && (
                <td
                    title={'Company Number'}
                    style={{ minHeight: 50, width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.company_number}
                    </a>
                </td>
            )}
            {state.columns?.includes('default_preparer') && (
                <td
                    title={'Default Preparer'}
                    style={{ minHeight: 50, width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.relations?.find((x: any) => x.type === 'assigned_to')?.name}
                    </a>
                </td>
            )}
            {state.columns?.includes('default_reviewer') && (
                <td
                    title={'Default Reviewer'}
                    style={{ minHeight: 50, width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.relations?.find((x: any) => x.type === 'reviewer')?.name}
                    </a>
                </td>
            )}
            {state.columns?.includes('default_partner') && (
                <td
                    title={'Default Partner'}
                    style={{ minHeight: 50, width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.relations?.find((x: any) => x.type === 'partner')?.name}
                    </a>
                </td>
            )}
            {state.columns?.includes('tasks_count') && (
                <td
                    title={'Tasks count'}
                    style={{ width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.tasks_count}
                    </a>
                </td>
            )}
            {state.columns?.includes('alerts_count') && (
                <td
                    title={'Alerts count'}
                    style={{ width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.alerts_count}
                    </a>
                </td>
            )}
            {state.columns?.includes('current_year_end') && (
                <td
                    title={'Current Year End'}
                    style={{ width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                >
                    <a
                        title={'Name'}
                        href={`/clients?id=${item.id}`}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--theme-ui-colors-text)',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        }}
                    >
                        {item?.current_year_end
                            ? moment(item?.current_year_end, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            : ''}
                    </a>
                </td>
            )}
            {Object.entries(state.organisation?.schema || {})
                .filter((x) => state.columns?.includes(x[0]))
                .sort((a, b) => {
                    const indexA = state.columns.indexOf(a[0]);
                    const indexB = state.columns.indexOf(b[0]);

                    if (indexA > indexB) {
                        return 1;
                    } else if (indexA < indexB) {
                        return -1;
                    }
                    return 0;
                })
                .map((entry: any) => {
                    let value;
                    if (entry[1]?.type === 'dropdown' && item?.client_data?.[entry[0]]) {
                        value = entry[1]?.dropdownOptions?.find(
                            (x: any) => x.id === item?.client_data?.[entry[0]]
                        )?.label;
                    } else {
                        value = item?.client_data?.[entry[0]] || '';
                    }
                    return (
                        <td
                            // @ts-ignore
                            key={entry[1]?.label}
                            // @ts-ignore
                            title={entry[1]?.label}
                            style={{ width: 200, textAlign: 'left', padding: 20, wordWrap: 'break-word' }}
                        >
                            <a
                                title={'Name'}
                                href={`/clients?id=${item.id}`}
                                style={{
                                    textDecoration: 'none',
                                    color: 'var(--theme-ui-colors-text)',
                                    width: '100%',
                                    height: '100%',
                                    display: 'block',
                                }}
                            >
                                {value}
                            </a>
                        </td>
                    );
                })}
        </tr>
    );
};
