import { Box, Button, Flex, Image, Paragraph } from 'theme-ui';

const rows = [
    {
        id: 'canvas-signature-1',
        font: 'Great Vibes',
    },
    {
        id: 'canvas-signature-2',
        font: 'Pacifico',
    },
];

const CompanySignature = ({ state, updateLocalState, localState, clearDraggableView }) => {
    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Flex sx={{ flexDirection: 'column' }}>
                <Paragraph sx={{ mb: 0, mt: 10 }}>Drag your company signature</Paragraph>
                <Flex
                    sx={{
                        cursor: 'grab',
                        mt: 20,
                        width: 300,
                        flexDirection: 'column',
                    }}
                >
                    <Flex sx={{ mb: 20, flexDirection: 'column' }}>
                        {rows?.map((row) => (
                            <Flex key={`csig_${row.font}`} sx={{ mb: 20 }}>
                                <Box sx={{ position: 'relative', width: 300, height: 45 }}>
                                    <canvas
                                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                                        id={row.id}
                                        width="300"
                                        height="45"
                                    />
                                    <Image
                                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}
                                        width="300"
                                        height="45"
                                        onDragStart={(e) => {
                                            e.dataTransfer.setData('text', e.target.id);
                                            e.dataTransfer.setData('offsetX', e.nativeEvent.offsetX);
                                            e.dataTransfer.setData('offsetY', e.nativeEvent.offsetY);
                                            e.dataTransfer.setData('svgText', state.organisation?.name);
                                            e.dataTransfer.setData('font', row.font);
                                            e.dataTransfer.setData('isCompanySignature', true);
                                        }}
                                        src={
                                            'data:image/svg+xml;utf8,' +
                                            encodeURIComponent(`
                                        <svg width="300" height="45" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="100%" height="100%" fill="none" stroke="#cccccc" stroke-width="2"/>
                                        </svg>
                                        `)
                                        }
                                    />
                                </Box>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
                <Button
                    onClick={() => {
                        const pdf = localState.pdfs[localState.pdfIndex];

                        pdf.locationToMove = null;
                        pdf.signatureLocations = [];

                        updateLocalState(localState);

                        clearDraggableView(localState);
                    }}
                    variant="light"
                    sx={{ width: 130, fontSize: 14 }}
                >
                    <i
                        style={{
                            cursor: 'pointer',
                            marginRight: '8px',
                        }}
                        className="fal fa-trash"
                        aria-hidden="true"
                    />
                    Clear All
                </Button>
            </Flex>
        </Flex>
    );
};

export default CompanySignature;
