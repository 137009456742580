/* eslint-disable no-undef */
import { Button, Flex, Input, Paragraph, Spinner } from 'theme-ui';
import { debounce } from 'debounce';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const roles = [
    { value: 'user', label: 'User' },
    { value: 'admin', label: 'Admin' },
];

const searchForUsers = async (newLocalState, updateLocalState, state, updateState) => {
    try {
        if (newLocalState.emailAddress?.length < 3) return;

        updateLocalState({ ...newLocalState, userSearchPending: true });
        const {
            data: { users },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
            params: {
                email_address: newLocalState.emailAddress,
            },
        });
        updateLocalState({ ...newLocalState, userSearchPending: false, usersFound: users });
    } catch (e) {
        return updateState({ ...state, confirmMessage: null, usersFound: [] });
    }
};

const addUserToTeam = async (id, state, updateState, localState, updateLocalState) => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams/${state.team.id}/users/${id}`,
            method: 'POST',
        });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams/${state.team.id}`,
        });

        updateState({
            ...state,
            loading: false,
            editError: null,
            fullTeamData: data,
        });

        updateLocalState({ ...localState, view: 'listTeamMembers', userSearchPending: false, usersFound: [] });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            editError:
                e?.response?.data?.message || 'Uh oh, there has been an error, please try again or contact support',
            confirmMessage: null,
        });
    }
};

const removeFromTeam = (id, state, updateState) => async () => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams/${state.team.id}/users/${id}`,
            method: 'DELETE',
        });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams/${state.team.id}`,
        });

        updateState({
            ...state,
            loading: false,
            editError: null,
            fullTeamData: data,
        });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            editError:
                e?.response?.data?.message || 'Uh oh, there has been an error, please try again or contact support',
            confirmMessage: null,
        });
    }
};

const saveTeam = async (state, updateState) => {
    try {
        if (!state.newTeamName) {
            return updateState({ ...state, editError: 'Please enter a team name', confirmMessage: null });
        }

        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        const method = state.team ? 'PUT' : 'POST';
        const urlSegment = state.team ? `/${state.team.id}` : '';

        const {
            data: { id },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams${urlSegment}`,
            method,
            data: {
                name: state.newTeamName,
            },
        });

        const {
            data: { teams, total: totalTeams },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/teams`,
        });

        updateState({
            ...state,
            loading: false,
            editError: null,
            team: { id, name: state.newTeamName },
            confirmMessage: 'Success!',
            teams,
            totalTeams,
        });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            editError: 'Uh oh, there has been an error, please try again or contact support',
            confirmMessage: null,
        });
    }
};

const ManageTeam = ({ state, updateState, organisation }) => {
    const [localState, updateLocalState] = useState({ view: 'listTeamMembers' });

    useEffect(() => {
        if (state.team?.id) {
            (async () => {
                try {
                    updateState({
                        ...state,
                        loading: true,
                    });

                    const { data: fullTeamData } = await axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/teams/${state.team.id}`,
                    });

                    updateState({
                        ...state,
                        loading: false,
                        fullTeamData,
                    });
                } catch (e) {
                    console.log(e);
                    updateState({
                        ...state,
                        loading: false,
                        error: 'There has been an error, please try again or contact support',
                    });
                }
            })();
        }
    }, []);

    const handleEmailSearchFieldChange = debounce(async (e) => {
        const tempState = { ...localState, emailAddress: e.target.value };
        updateLocalState(tempState);
        await searchForUsers(tempState, updateLocalState, state, updateState);
    }, 500);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Flex>
                <Paragraph
                    sx={{
                        width: '95px',
                        height: '40px',
                        p: '10px 5px',
                        mb: '25px',
                        fontSize: '13px',
                        cursor: 'pointer',
                        border: '1px solid #eaecf0',
                        mt: 20,
                    }}
                    onClick={async () => {
                        updateState({
                            ...state,
                            teamsView: 'list',
                            user: null,
                            newRole: null,
                            confirmMessage: null,
                            editError: null,
                            error: null,
                            newTeamName: null,
                        });
                    }}
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '12px',
                            marginLeft: '9px',
                            marginRight: '6px',
                        }}
                        className={`fal fa-chevron-left`}
                    />{' '}
                    Back
                </Paragraph>
                {localState.view === 'listTeamMembers' && (
                    <Button
                        sx={{
                            border: '1px solid #EFEFEF',
                            backgroundColor: 'white',
                            color: '#000',
                            fontSize: 14,
                            height: 40,
                            width: 100,
                            ml: 10,
                            mt: 20,
                        }}
                        onClick={async () => {
                            await saveTeam(state, updateState);
                        }}
                    >
                        Save
                    </Button>
                )}
            </Flex>

            {localState.view === 'add' && (
                <Flex sx={{ mt: 20, flexDirection: 'column' }}>
                    <Paragraph sx={{ fontWeight: 600 }}>Search for user by email address</Paragraph>
                    <Flex sx={{ height: 50 }}>
                        <Input
                            onChange={handleEmailSearchFieldChange}
                            sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', fontSize: 14 }}
                        />
                        {localState.userSearchPending && <Spinner sx={{ width: 30, ml: 10, mt: '5px' }} />}
                    </Flex>
                    <Flex sx={{ mt: 20 }} />
                    {localState.usersFound?.map((user, idx) => (
                        <Flex
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: '10px',
                                width: 500,
                                borderTop: '1px solid #EFEFEF',
                                borderBottom: '1px solid #EFEFEF',
                            }}
                            key={`user_found_${idx}`}
                        >
                            <Paragraph sx={{ mt: '5px', mr: 10 }}>{user.email_address}</Paragraph>
                            <Button
                                sx={{
                                    border: '1px solid #EFEFEF',
                                    backgroundColor: 'white',
                                    color: '#000',
                                    fontSize: 14,
                                    height: 40,
                                    width: 100,
                                    mt: 10,
                                    mb: 10,
                                }}
                                onClick={async () => {
                                    await addUserToTeam(user.raw_id, state, updateState, localState, updateLocalState);
                                }}
                            >
                                Add
                            </Button>
                        </Flex>
                    ))}

                    <Button
                        sx={{
                            border: '1px solid #EFEFEF',
                            backgroundColor: 'white',
                            color: '#000',
                            fontSize: 14,
                            height: 40,
                            width: 100,
                            mt: 20,
                            mb: 10,
                        }}
                        onClick={async () => {
                            updateLocalState({ ...localState, view: 'listTeamMembers' });
                        }}
                    >
                        Cancel
                    </Button>
                </Flex>
            )}

            {localState.view === 'listTeamMembers' && (
                <Flex sx={{ mt: 20, flexDirection: 'column' }}>
                    <Paragraph sx={{ width: 110, fontWeight: 600 }}>Team name</Paragraph>
                    <Input
                        value={state.newTeamName}
                        onChange={(e) =>
                            updateState({
                                ...state,
                                newTeamName: e.target.value,
                            })
                        }
                        sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', mb: 20 }}
                    />
                </Flex>
            )}

            {localState.view === 'listTeamMembers' && (
                <>
                    <Flex sx={{ justifyContent: 'space-between', mb: 10, mt: 10, width: 550 }}>
                        <Paragraph sx={{ fontWeight: 600, mt: 10 }}>Team members</Paragraph>
                        <Button
                            sx={{
                                border: '1px solid #ccc',
                                backgroundColor: 'white',
                                color: '#000',
                                fontSize: 14,
                                height: 40,
                                width: 180,
                            }}
                            onClick={async () => {
                                if (!state.team) {
                                    return updateState({
                                        ...state,
                                        editError: 'Please add a team name and save your team first',
                                        confirmMessage: '',
                                    });
                                }
                                updateState({ ...state, confirmMessage: '' });
                                updateLocalState({ ...localState, view: 'add', usersFound: [] });
                            }}
                        >
                            Add a new member
                        </Button>
                    </Flex>

                    <Flex
                        sx={{
                            width: 550,
                            borderLeft: '1px solid #ccc',
                            borderRight: '1px solid #ccc',
                            borderTop: '1px solid #ccc',
                            borderBottom: '1px solid #ccc',
                            backgroundColor: 'lightGrey',
                        }}
                    >
                        <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}>
                            <Paragraph>Email address</Paragraph>
                        </Flex>
                        <Flex sx={{ width: 50 }}></Flex>
                    </Flex>
                    {state?.fullTeamData?.members?.map((x, idx) => (
                        <Flex
                            key={x.name}
                            sx={{
                                borderLeft: '1px solid #ccc',
                                borderRight: '1px solid #ccc',
                                borderBottom:
                                    idx === state?.fullTeamData?.members.length - 1 ? '1px solid #ccc' : 'unset',
                                width: 550,
                            }}
                        >
                            <Flex
                                sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}
                            >
                                <Paragraph>{x.email_address}</Paragraph>
                            </Flex>
                            <Flex sx={{ padding: 20, width: 50, justifyContent: 'center', alignItems: 'center' }}>
                                {organisation?.user?.role === 'admin' && (
                                    <>
                                        <i
                                            style={{
                                                fontSize: '12px',
                                                cursor: 'pointer',
                                                color: 'red',
                                            }}
                                            className="fal fa-trash"
                                            aria-hidden="true"
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    showUnlinkUserFromTeamConfirmation: true,
                                                    confirmCallback: removeFromTeam(x.id, state, updateState),
                                                })
                                            }
                                        />
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    ))}
                </>
            )}

            {state.editError && <Paragraph sx={{ mt: 20, color: 'red', width: 600 }}>{state.editError}</Paragraph>}
            {state.confirmMessage && <Paragraph sx={{ mt: 20, color: 'green' }}>{state.confirmMessage}</Paragraph>}
        </Flex>
    );
};

export default ManageTeam;
