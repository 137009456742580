import { Button, Flex, Paragraph } from 'theme-ui';
import Divider from '../divider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import moment from 'moment';

const SubTaskComments = ({ state, updateState, triggerSave }) => {
    const [localState, updateLocalState] = useState({ content: '', addComment: false, editIndex: null });

    const subTask = state.fullTaskData?.subtasks?.find((x) => x.uiKey === state.subTaskUIKey);

    return (
        <Flex sx={{ mt: 25, ml: 15, height: 500, flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: '800' }}>Comments</Paragraph>
            <Divider width="95%" />
            {!localState.addComment && (
                <Button
                    onClick={() => updateLocalState({ ...localState, addComment: true, editIndex: null })}
                    sx={{ height: 40, width: 150, mb: 10 }}
                >
                    Add comment
                </Button>
            )}
            {localState.addComment && (
                <Flex sx={{ mb: 10 }}>
                    <Button
                        onClick={() =>
                            updateLocalState({ ...localState, addComment: false, content: '', editIndex: null })
                        }
                        sx={{
                            height: 40,
                            width: 120,
                            mb: 10,
                            border: '1px solid lightGrey',
                            background: 'white',
                            color: 'black',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            const newState = {
                                ...state,
                                fullTaskData: {
                                    ...state.fullTaskData,
                                    subtasks: state.fullTaskData.subtasks.map((x) => {
                                        if (!x.comments) {
                                            x.comments = [];
                                        }

                                        if (x.uiKey === state.subTaskUIKey) {
                                            if (localState.editIndex !== null) {
                                                x.comments[localState.editIndex] = {
                                                    content: localState.content,
                                                    last_updated: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                                                };
                                            } else {
                                                x.comments.unshift({
                                                    content: localState.content,
                                                    last_updated: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                                                    creator: `${state.organisation?.user?.first_name} ${state.organisation?.user?.last_name}`,
                                                });
                                            }
                                        }
                                        return x;
                                    }),
                                },
                            };

                            updateState(newState);
                            updateLocalState({ ...localState, addComment: false, content: '', editIndex: null });

                            triggerSave(newState);
                        }}
                        sx={{ ml: 10, height: 40, width: 120, mb: 10 }}
                    >
                        Save
                    </Button>
                </Flex>
            )}

            {localState.addComment && (
                <Flex sx={{ mb: 50 }}>
                    <ReactQuill
                        theme="snow"
                        value={localState.content}
                        style={{ height: 200, width: 470 }}
                        onChange={(val) =>
                            updateLocalState({
                                ...localState,
                                content: val,
                            })
                        }
                    />
                </Flex>
            )}

            {/* <Divider width="95%" /> */}

            <Flex sx={{ flexDirection: 'column', overflowY: 'scroll', mb: -170 }}>
                {subTask?.comments?.map((x, idx) => (
                    <Flex sx={{ flexDirection: 'column' }} key={`subtask_comment_${idx}`}>
                        <Paragraph dangerouslySetInnerHTML={{ __html: x.content }} />
                        <Flex>
                            <Paragraph sx={{ fontSize: 12 }}>
                                {moment(x.last_updated).format('HH:mm Do MMM YY')}
                            </Paragraph>
                            <i
                                style={{
                                    marginLeft: 20,
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                }}
                                className="fas fa-pencil"
                                aria-hidden="true"
                                onClick={() => {
                                    updateLocalState({
                                        ...localState,
                                        addComment: true,
                                        content: x.content,
                                        editIndex: idx,
                                    });
                                }}
                            />
                            <i
                                style={{
                                    marginLeft: '15px',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    color: 'red',
                                }}
                                className="fal fa-trash"
                                aria-hidden="true"
                                onClick={async () => {
                                    const newState = {
                                        ...state,
                                        fullTaskData: {
                                            ...state.fullTaskData,
                                            subtasks: state.fullTaskData.subtasks.map((x) => {
                                                return {
                                                    ...x,
                                                    comments: x.comments.filter(
                                                        (x, commentsIdx) => commentsIdx !== idx
                                                    ),
                                                };
                                            }),
                                        },
                                    };
                                    updateState(newState);
                                    triggerSave(newState);
                                }}
                            />
                        </Flex>
                        {x.creator && <Paragraph sx={{ fontSize: 12, mt: '10px' }}>Created by {x.creator}</Paragraph>}
                        <Divider width="95%" />
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

export default SubTaskComments;
