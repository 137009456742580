/* eslint-disable no-undef */
import axios from 'axios';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button, Flex, Input, Paragraph, Switch, Textarea } from 'theme-ui';
import { buildIncrementOptions, convertMinutesToHours } from '../../pages/timesheets';
import theme from '../../theme';
import Divider from '../divider';
import buildInvoiceHtml from './build-invoice-html';
import InvoiceOptions, { replaceTokens, vatOptions } from './invoice-options';
import ConfirmDialog from '../tasks/confirm-dialog';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadInvoices } from './invoices';

const summariseDescription = async (day_entry_ids, state, updateState, index, lineItems, setLineItems) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { summary },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summarise-grouped-descriptions`,
            method: 'POST',
            data: {
                day_entry_ids,
            },
        });

        setLineItems(
            lineItems.map((item, existingIdx) => {
                if (index === existingIdx) {
                    item.description = summary;
                }
                return item;
            })
        );

        updateState({ ...state, loading: false });
    } catch (e) {
        updateState({ ...state, loading: false });
        toast(e?.response?.data?.message || 'Uh oh, there has been an issue, please try again or contact support', {
            type: 'error',
        });
    }
};

export const generatePdf = async (
    state,
    updateState,
    lineItems,
    extraFormState,
    setInvoices,
    emailingInvoice,
    saveOnly
) => {
    try {
        updateState({ ...state, loading: true });

        const client = state.clientInView;

        const address = client.companies_house_data?.profile?.registeredOfficeAddress;

        const clientAddress = address
            ? [client.name, address.addressLineOne, address.locality, address.postalCode, address.country]
            : [client.name];

        const configuredOrgAddress = client?.organisation?.default_visualisation?.address;

        const raw_totals = lineItems.reduce(
            (accum, lineItem) => {
                accum.subtotal = new Decimal(accum.subtotal)
                    .plus(new Decimal(lineItem.timeSpent).div(new Decimal(60)).times(new Decimal(lineItem.rate)))
                    .toNumber();

                accum.vat = new Decimal(0)
                    .plus(
                        vatOptions.find((x) => x.value === lineItem.vatRate)?.multiplier > 1
                            ? new Decimal(vatOptions.find((x) => x.value === lineItem.vatRate)?.multiplier - 1).times(
                                  new Decimal(accum.subtotal)
                              )
                            : 0
                    )
                    .toNumber();

                accum.total = new Decimal(accum.subtotal).plus(new Decimal(accum.vat)).toNumber();
                return accum;
            },
            {
                subtotal: 0,
                vat: 0,
                total: 0,
            }
        );

        const services = [
            ...(Object.entries(state.organisation?.workflow_definition?.services || {})?.map(([key, value]) => ({
                value: key,
                label: value.label,
            })) || []),
            ...(state.organisation?.default_visualisation?.invoiceOptions?.customServiceOptions || []).map((x) => ({
                value: x.label,
                label: x.label,
            })),
        ];

        const model = {
            pdfColumns: extraFormState.pdfColumns,
            logo:
                client?.organisation?.default_visualisation?.invoicePdfLogo ||
                client?.organisation?.default_visualisation?.logo,
            organisation_address: {
                name: client?.organisation?.name,
                line_1: configuredOrgAddress?.addressLineOne,
                line_2: configuredOrgAddress?.locality,
                line_3: configuredOrgAddress?.postalCode,
                line_4: configuredOrgAddress?.country,
                line_5: `VAT Number: ${configuredOrgAddress?.vatNumber}`,
            },
            contact_address: clientAddress,
            line_items: lineItems?.map((lineItem) => {
                let time_period = '';
                if (lineItem.from) {
                    time_period =
                        lineItem.from === lineItem.to || !lineItem.to
                            ? moment(lineItem.from).format('DD/MM/YYYY')
                            : `${moment(lineItem.from).format('DD/MM/YYYY')} to ${moment(lineItem.to).format(
                                  'DD/MM/YYYY'
                              )}`;
                }

                return {
                    id: lineItem.id || -1,
                    grouped_entries: lineItem.groupedEntries?.map((x) => x.id) || [],
                    description: lineItem.description,
                    type: lineItem.type,
                    from: lineItem.from,
                    to: lineItem.to,
                    vatRate: lineItem.vatRate,
                    timeSpent: lineItem.timeSpent,
                    rate: lineItem.rate,
                    time_period,
                    service: lineItem.service,
                    service_formatted: lineItem.service
                        ? services?.find((x) => x.value === lineItem.service)?.label
                        : null,
                    vat: vatOptions.find((x) => x.value === lineItem.vatRate)?.label || 'No VAT',
                    time_spent: convertMinutesToHours(lineItem.timeSpent),
                    rate_formatted: new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(lineItem.rate || 0),
                    total: new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(calculateBillableAmount(lineItem.timeSpent, lineItem.rate, 1)),
                };
            }),
            totals: {
                subtotal: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                    raw_totals.subtotal
                ),
                vat: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                    raw_totals.vat
                ),
                total: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                    raw_totals.total
                ),
            },
            raw_totals,
            comment: extraFormState.comment || '',
            customFields: (extraFormState.customFields || [])?.map((x) => ({
                ...x,
                defaultValue: replaceTokens(x?.defaultValue, state),
            })),
            invoiceDate: extraFormState.invoiceDate,
            payableBy: extraFormState.bankDetails?.payableBy,
            invoice_date: moment(extraFormState.invoiceDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            invoice_number: extraFormState.autoGenerateInvoiceNumber
                ? '_INVOICE_NUMBER_'
                : extraFormState.invoiceNumber,
            bank_details: {
                payable_by: moment(extraFormState.bankDetails.payableBy, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            },
            paramsUsedForSearch: state.paramsUsedForSearch,
            recurring_props: {
                is_invoice_scheduled: extraFormState.is_invoice_scheduled,
                invoice_days_in_advance: extraFormState.invoice_days_in_advance,
                invoice_interval: extraFormState.invoice_interval,
                invoice_day: extraFormState.invoice_day,
                invoice_day_of_month: extraFormState.invoice_day_of_month,
                invoice_interval_every: extraFormState.invoice_interval_every,
                invoice_weekly_on: extraFormState.invoice_weekly_on,
                invoice_monthly_on: extraFormState.invoice_monthly_on,
                invoice_month_of_year: extraFormState.invoice_month_of_year,
                invoice_recurring_start_date: extraFormState.invoice_recurring_start_date,
                invoice_recurring_end: extraFormState.invoice_recurring_end,
                invoice_recurring_end_date: extraFormState.invoice_recurring_end_date,
                auto_send_invoice_emails: extraFormState.auto_send_invoice_emails,
            },
        };

        model.tableHeaderColor =
            state.clientInView?.organisation?.default_visualisation?.invoiceOptions?.tableHeaderColor || '#007BFF';

        const {
            data: { url, invoice_uuid, invoice_number },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/invoices`,
            method: 'POST',
            data: {
                client_uuid: state.clientInView.id,
                html: buildInvoiceHtml(model, extraFormState.pdfColumns),
                model,
                invoice_uuid: state.invoice?.uuid,
                save_only: saveOnly,
            },
        });

        if (!emailingInvoice && !saveOnly) {
            const a = window.document.createElement('a');

            a.href = url;
            a.target = '_blank';
            a.download = 'Invoice.pdf';

            a.click();
        }

        if (saveOnly) {
            toast.success('Invoice saved');
        }

        return await loadInvoices(
            {
                ...state,
                loading: false,
                invoice: { uuid: invoice_uuid, model, invoice_number, pdfUrl: url },
            },
            updateState,
            setInvoices
        );
    } catch (e) {
        if (e.response?.data?.code === 'INV_01') {
            toast(e?.response?.data?.message, {
                type: 'error',
                autoClose: 5000,
                position: 'top-right',
            });

            return updateState({
                ...state,
                loading: false,
                alreadyAccountedFor: e?.response?.data?.invoices,
            });
        }
        console.log(e);
        updateState({
            ...state,
            loading: false,
        });
        toast(e?.response?.data?.message || 'We were unable to generate an invoice at this time', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
        return null;
    }
};

const importTimeEntries = async (state, updateState) => {
    if (!state.fromDate || !state.toDate) return;

    updateState({ ...state, loading: true });

    const [
        {
            data: { entries: summaryEntries },
        },
        {
            data: { total_time_to_approve, chargeable_time },
        },
    ] = await Promise.all([
        axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summary`,
            params: {
                from: state.fromDate,
                to: state.toDate,
                status: 'locked',
                client_uuid: state.clientInView.id,
                group_by_service: '1',
            },
        }),
        axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/open-time-stats`,
            params: {
                from: state.fromDate,
                to: state.toDate,
                status: 'open',
                client_uuid: state.clientInView.id,
            },
        }),
    ]);

    let results = summaryEntries;
    if (state.groupByService) {
        const grouped = {};
        results.forEach((result) => {
            const key = `${result.orgUserId}_${result.timeWeekEntry.service}`;
            if (grouped[key]) {
                grouped[key].total = grouped[key].total + parseFloat(result.timeSpent);
                grouped[key].groupedEntries.push(JSON.parse(JSON.stringify(result)));
            } else {
                result.total = parseFloat(result.timeSpent);
                grouped[key] = result;
                grouped[key].groupedEntries = [JSON.parse(JSON.stringify(result))];
            }

            if (grouped[key].from) {
                grouped[key].from = moment
                    .min(moment(result.date, 'YYYY-MM-DD'), moment(grouped[key].from, 'YYYY-MM-DD'))
                    .format('YYYY-MM-DD');
            } else {
                grouped[key].from = result.date;
            }

            if (grouped[key].to) {
                grouped[key].to = moment
                    .max(moment(result.date, 'YYYY-MM-DD'), moment(grouped[key].to, 'YYYY-MM-DD'))
                    .format('YYYY-MM-DD');
            } else {
                grouped[key].to = result.date;
            }
        });
        results = Object.values(grouped);
    } else {
        results = results.map((x) => ({
            ...x,
            from: moment(x.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            to: moment(x.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        }));
    }

    updateState({
        ...state,
        loading: false,
        timeEntryResults: results,
        total_time_to_approve,
        chargeable_time,
        paramsUsedForSearch: {
            from: state.fromDate,
            to: state.toDate,
            groupByService: state.groupByService,
        },
    });
};

const addLineItem = (newItemState, setLineItems, lineItems) => {
    const clonedState = { ...newItemState };

    setLineItems([
        ...lineItems,
        {
            description: clonedState.description,
            timeSpent: clonedState.timeSpent,
            from: clonedState.from,
            to: clonedState.to,
            vatRate: clonedState.vatRate,
            billableAmount: parseFloat(clonedState.billableAmount || 0),
            rate: parseFloat(clonedState.rate || 0),
            type: 'manual',
            service: clonedState.service?.value,
        },
    ]);
};

const GenerateInvoice = ({ state, updateState }) => {
    const [lineItems, setLineItems] = useState(state.invoice?.model?.line_items || []);

    useEffect(() => {
        importTimeEntries(state, updateState);
    }, []);

    const services = [
        ...(Object.entries(state.organisation?.workflow_definition?.services || {})?.map(([key, value]) => ({
            value: key,
            label: value.label,
        })) || []),
        ...(state.organisation?.default_visualisation?.invoiceOptions?.customServiceOptions || []).map((x) => ({
            value: x.label,
            label: x.label,
        })),
    ];

    const timeRecordingOptions = buildIncrementOptions(
        state.organisation?.default_visualisation?.timesheets?.defaultIncrements || 15
    );

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph sx={{ fontSize: 25, mt: 20 }}>Invoice</Paragraph>
            <Divider />

            {state.showDeleteInvoiceConfirmation ? (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteInvoiceConfirmation"
                    text="Are you sure you wish to delete this invoice?"
                />
            ) : null}
            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ flexDirection: 'column', mb: 20 }}>
                        <Paragraph sx={{ mr: 20, mb: 20 }}>Fetch Time Entries</Paragraph>
                        <Flex>
                            <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                <Paragraph sx={{ mt: 0, mb: 10 }} id="from-label">
                                    From
                                </Paragraph>
                                <Input
                                    value={state.fromDate}
                                    onChange={(e) => updateState({ ...state, fromDate: e.target.value })}
                                    type="date"
                                />
                            </Flex>
                            <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                <Paragraph sx={{ mt: 0, mb: 10 }} id="to-label">
                                    To
                                </Paragraph>
                                <Input
                                    value={state.toDate}
                                    onChange={(e) => updateState({ ...state, toDate: e.target.value })}
                                    type="date"
                                />
                            </Flex>
                            <Flex sx={{ mt: 25, alignItems: 'center' }}>
                                <Paragraph sx={{ width: 260, mr: '5px' }}>Group by service</Paragraph>
                                <Switch
                                    defaultChecked={state.groupByService}
                                    onClick={(e) => updateState({ ...state, groupByService: !state.groupByService })}
                                />
                            </Flex>
                            <Button
                                onClick={async () => {
                                    await importTimeEntries(state, updateState);
                                }}
                                sx={{ ml: -100, height: 37, mt: 30 }}
                            >
                                <i style={{ marginRight: '7px' }} className="fa fa-search" />
                                Search
                            </Button>
                            <Button
                                variant="light"
                                onClick={async () => {
                                    setLineItems([]);
                                }}
                                sx={{ ml: 20, height: 37, mt: 30 }}
                            >
                                <i style={{ marginRight: '7px' }} className="fa fa-sync-alt" />
                                Clear All
                            </Button>
                        </Flex>
                        {isNaN(state.total_time_to_approve) ? null : (
                            <Paragraph sx={{ mr: 20, mt: 20 }}>
                                {convertMinutesToHours(state.total_time_to_approve)}
                                {` (£${new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    state.chargeable_time
                                )}) of chargeable time waiting to be approved for this time period`}
                            </Paragraph>
                        )}
                    </Flex>

                    <Button
                        onClick={() => {
                            addLineItem(
                                {
                                    description: '',
                                    from: '',
                                    to: '',
                                    vatRate: null,
                                    timeSpent: 0,
                                    billableAmount: 0,
                                    rate: 0,
                                    type: 'manual',
                                    service: null,
                                },
                                setLineItems,
                                lineItems
                            );
                        }}
                        sx={{ mb: 20, width: 150 }}
                    >
                        <i
                            style={{
                                fontSize: '14px',
                                cursor: 'pointer',
                                marginRight: '5px',
                            }}
                            className="fas fa-plus-circle"
                            aria-hidden="true"
                        />
                        Add line item
                    </Button>

                    <table
                        style={{
                            borderCollapse: 'collapse',
                            width: 'auto',
                        }}
                    >
                        <thead
                            style={{
                                backgroundColor: theme.colors.primary,
                                opacity: 0.9,
                                height: 50,
                                color: 'white',
                            }}
                        >
                            <tr style={{ borderTop: '0px solid lightGrey' }}>
                                <th style={{ width: 150, textAlign: 'left', paddingLeft: 20 }}>Service</th>
                                <th style={{ width: 350, textAlign: 'left', paddingLeft: 20 }}>Description</th>
                                <th style={{ width: 200, textAlign: 'left', paddingLeft: 20 }}>Time Period</th>
                                <th style={{ width: 120, textAlign: 'center' }}>Time Spent (minutes)</th>
                                <th style={{ width: 120, textAlign: 'center' }}>Rate/hr (£)</th>
                                <th style={{ width: 300, textAlign: 'center' }}>VAT Rate (£)</th>
                                <th style={{ width: 200, textAlign: 'center' }}>Billable Amount (£)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ borderCollapse: 'collapse' }}>
                            {lineItems.map((item, index) => (
                                <tr
                                    style={{
                                        borderBottom: '1px solid lightGrey',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        boxShadow: 0,
                                        border: state.alreadyAccountedFor?.find((x) => x.id === item.id)
                                            ? '1px solid red'
                                            : 'none',
                                    }}
                                    key={index}
                                >
                                    <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none' }}>
                                        <ReactSelect
                                            aria-labelledby="service-label"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                    width: '280px',
                                                    minHeight: '40px',
                                                    marginTop: '10px',
                                                    borderRadius: 10,
                                                    marginBottom: '10px',
                                                    border: '1px solid #a3a69b',
                                                    fontSize: '14px',
                                                }),
                                            }}
                                            value={item.service ? services.find((x) => x.value === item.service) : null}
                                            onChange={(c) =>
                                                setLineItems(
                                                    lineItems?.map((item, idx) => {
                                                        if (idx === index) {
                                                            item.service = c.value;
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }
                                            placeholder="Select..."
                                            options={services}
                                        />
                                    </td>
                                    <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none' }}>
                                        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                                            <Textarea
                                                sx={{ height: 60, width: 300 }}
                                                value={item.description}
                                                rows={2}
                                                onChange={(e) =>
                                                    setLineItems(
                                                        lineItems?.map((item, idx) => {
                                                            if (idx === index) {
                                                                item.description = e.target.value;
                                                            }
                                                            return item;
                                                        })
                                                    )
                                                }
                                            />
                                            {item.grouped_entries?.length ? (
                                                <Button
                                                    sx={{ mt: 20 }}
                                                    onClick={async () => {
                                                        await summariseDescription(
                                                            item.grouped_entries,
                                                            state,
                                                            updateState,
                                                            index,
                                                            lineItems,
                                                            setLineItems
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            fontSize: '14px',
                                                            cursor: 'pointer',
                                                            marginRight: '5px',
                                                        }}
                                                        className="fas fa-sync"
                                                        aria-hidden="true"
                                                    />
                                                    Summarise with AI
                                                </Button>
                                            ) : null}
                                        </Flex>
                                    </td>

                                    <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none' }}>
                                        <Flex sx={{ flexDirection: 'column' }}>
                                            <Paragraph sx={{ mb: 10 }}>From</Paragraph>
                                            <Input
                                                onChange={(e) =>
                                                    setLineItems(
                                                        lineItems?.map((item, idx) => {
                                                            if (idx === index) {
                                                                item.from = e.target.value;
                                                            }
                                                            return item;
                                                        })
                                                    )
                                                }
                                                value={item.from}
                                                sx={{ mb: 10 }}
                                                type="date"
                                            />
                                            <Paragraph sx={{ mb: 10 }}>To</Paragraph>
                                            <Input
                                                type="date"
                                                onChange={(e) =>
                                                    setLineItems(
                                                        lineItems?.map((item, idx) => {
                                                            if (idx === index) {
                                                                item.to = e.target.value;
                                                            }
                                                            return item;
                                                        })
                                                    )
                                                }
                                                value={item.to}
                                            />
                                        </Flex>
                                    </td>
                                    <td
                                        style={{
                                            padding: 25,
                                            textAlign: 'center',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                        }}
                                    >
                                        <ReactSelect
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                    width: '150px',
                                                    minHeight: '40px',
                                                    marginTop: '0px',
                                                    borderRadius: 10,
                                                    marginBottom: '10px',
                                                    fontSize: '14px',
                                                    border: '1px solid #a3a69b',
                                                }),
                                            }}
                                            onChange={(c) => {
                                                if (parseFloat(c.value) < 0) return;

                                                setLineItems(
                                                    lineItems?.map((item, idx) => {
                                                        if (idx === index) {
                                                            item.timeSpent = c.value;
                                                            item.billableAmount = calculateBillableAmount(
                                                                item.timeSpent,
                                                                item.rate,
                                                                1
                                                            );
                                                        }
                                                        return item;
                                                    })
                                                );
                                            }}
                                            placeholder="Select..."
                                            value={
                                                item.timeSpent || item.timeSpent === 0
                                                    ? {
                                                          value: item.timeSpent,
                                                          label: convertMinutesToHours(item.timeSpent),
                                                      }
                                                    : null
                                            }
                                            options={timeRecordingOptions}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: 25,
                                            textAlign: 'center',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                        }}
                                    >
                                        <Input
                                            sx={{ textAlign: 'center', height: 50, width: 130 }}
                                            value={item.rate}
                                            type="number"
                                            onChange={(e) => {
                                                if (parseFloat(e.target.value) < 0) return;

                                                setLineItems(
                                                    lineItems?.map((item, idx) => {
                                                        if (idx === index) {
                                                            item.rate = e.target.value;
                                                            item.billableAmount = calculateBillableAmount(
                                                                item.timeSpent,
                                                                item.rate,
                                                                1
                                                            );
                                                        }
                                                        return item;
                                                    })
                                                );
                                            }}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: 25,
                                            textAlign: 'center',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                        }}
                                    >
                                        <ReactSelect
                                            id="vat-rate"
                                            options={vatOptions}
                                            value={
                                                item.vatRate ? vatOptions.find((x) => x.value === item.vatRate) : null
                                            }
                                            onChange={({ value }) =>
                                                setLineItems(
                                                    lineItems?.map((item, idx) => {
                                                        if (idx === index) {
                                                            item.vatRate = value;
                                                            item.billableAmount = calculateBillableAmount(
                                                                item.timeSpent,
                                                                item.rate,
                                                                1
                                                            );
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    borderRadius: '15px',
                                                }),
                                            }}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: 25,
                                            textAlign: 'center',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                        }}
                                    >
                                        <Input
                                            type="number"
                                            min={0}
                                            sx={{ textAlign: 'center', height: 50, width: 130 }}
                                            value={item.billableAmount || ''}
                                            onChange={(e) => {
                                                if (parseFloat(e.target.value) < 0) return;

                                                setLineItems(
                                                    lineItems?.map((item, idx) => {
                                                        if (idx === index) {
                                                            item.billableAmount = parseFloat(e.target.value);
                                                            item.rate = calculateRateWhenBillableAmountChanges(
                                                                item.timeSpent,
                                                                item.billableAmount,
                                                                1
                                                            );
                                                        }
                                                        return item;
                                                    })
                                                );
                                            }}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: 25,
                                            textAlign: 'center',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 140,
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setLineItems(lineItems.filter((x, idx) => idx !== index));
                                            }}
                                            variant="light"
                                        >
                                            <i
                                                style={{
                                                    fontSize: '12px',
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                    marginRight: '7px',
                                                }}
                                                className="fal fa-trash"
                                                aria-hidden="true"
                                            />
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Divider mt={50} />

                    <Flex sx={{ justifyContent: 'space-between' }}>
                        <Flex sx={{ flexDirection: 'column', alignSelf: 'flex-start' }}>
                            {lineItems?.length ? (
                                <>
                                    <InvoiceOptions state={state} updateState={updateState} lineItems={lineItems} />
                                </>
                            ) : null}
                        </Flex>
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Flex sx={{ mb: 20, alignItems: 'center' }}>
                                <Paragraph sx={{ fontSize: 24, mr: 10, width: 140 }}>Subtotal:</Paragraph>
                                <Paragraph sx={{ fontSize: 24 }}>
                                    £
                                    {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(
                                        lineItems.reduce((total, item) => {
                                            if (!parseFloat(item.rate)) return total;
                                            if (!parseFloat(item.timeSpent)) return total;

                                            return total.add(
                                                new Decimal(item.rate).times(
                                                    new Decimal(item.timeSpent).div(new Decimal(60))
                                                )
                                            );
                                        }, new Decimal(0))
                                    )}
                                </Paragraph>
                            </Flex>
                            <Flex sx={{ mb: 30, alignItems: 'center' }}>
                                <Paragraph sx={{ fontSize: 24, mr: 10, width: 140 }}>VAT at 20%:</Paragraph>
                                <Paragraph sx={{ fontSize: 24 }}>
                                    £
                                    {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(
                                        lineItems.reduce((total, item) => {
                                            if (!parseFloat(item.rate)) return total;
                                            if (!parseFloat(item.timeSpent)) return total;
                                            if (!item.vatRate) return total;

                                            return total.add(
                                                new Decimal(item.rate || 1)
                                                    .times(new Decimal(item.timeSpent).div(new Decimal(60)))
                                                    .times(
                                                        vatOptions.find((x) => x.value === item.vatRate)?.multiplier - 1
                                                    )
                                            );
                                        }, new Decimal(0))
                                    )}
                                </Paragraph>
                            </Flex>
                            <Flex sx={{ mb: 30, alignItems: 'center' }}>
                                <Paragraph sx={{ fontSize: 24, mr: 10, width: 140, fontWeight: 600 }}>Total:</Paragraph>
                                <Paragraph sx={{ fontSize: 24 }}>
                                    £
                                    {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(
                                        lineItems.reduce((total, item) => {
                                            if (!parseFloat(item.rate)) return total;
                                            if (!parseFloat(item.timeSpent)) return total;

                                            return total.add(
                                                new Decimal(item.rate)
                                                    .times(new Decimal(item.timeSpent).div(new Decimal(60)))
                                                    .times(
                                                        item.vatRate
                                                            ? vatOptions.find((x) => x.value === item.vatRate)
                                                                  ?.multiplier
                                                            : 1
                                                    )
                                            );
                                        }, new Decimal(0))
                                    )}
                                </Paragraph>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                {state.timeEntryResults?.length ? (
                    <Flex sx={{ ml: 30, flexDirection: 'column' }}>
                        <Flex sx={{ alignItems: 'center' }}>
                            <Paragraph>Time Recorded</Paragraph>
                            <Button
                                onClick={() => {
                                    setLineItems([
                                        ...lineItems,
                                        ...state.timeEntryResults
                                            .filter((result) => !lineItems?.find((x) => x.id === result.id))
                                            .map((result, idx) => buildLineItem(result, state)),
                                    ]);
                                }}
                                sx={{ height: 40, width: 100, ml: 10 }}
                            >
                                Add All
                            </Button>
                        </Flex>
                        <Divider width={250} />
                        {state.timeEntryResults?.map((result, idx) => {
                            const onTimeSheet = lineItems?.find((x) => x.id === result.id);
                            return (
                                <Flex
                                    key={`result_${idx}`}
                                    sx={{
                                        flexDirection: 'column',
                                        borderRadius: 15,
                                        padding: 20,
                                        border: '1px solid #efefef',
                                        mb: 10,
                                    }}
                                >
                                    <Paragraph sx={{ mb: 10 }}>
                                        {state.paramsUsedForSearch?.groupByService
                                            ? state.organisation?.workflow_definition?.services?.[
                                                  result.timeWeekEntry?.service
                                              ]?.label
                                            : result.timeWeekEntry?.description}
                                    </Paragraph>
                                    <Paragraph sx={{ mb: 10 }}>
                                        {!state.paramsUsedForSearch?.groupByService
                                            ? state.organisation?.workflow_definition?.services?.[
                                                  result.timeWeekEntry?.service
                                              ]?.label
                                            : null}
                                    </Paragraph>
                                    <Paragraph>
                                        Time Spent:{' '}
                                        {convertMinutesToHours(parseFloat(result.total || result.timeSpent))}
                                    </Paragraph>
                                    <Button
                                        onClick={() => {
                                            setLineItems([...lineItems, buildLineItem(result, state)]);
                                        }}
                                        sx={{ height: 40, width: 100, mt: 10, opacity: onTimeSheet ? 0.5 : 1 }}
                                        disabled={onTimeSheet}
                                    >
                                        Add
                                    </Button>
                                </Flex>
                            );
                        })}
                    </Flex>
                ) : null}
            </Flex>
        </Flex>
    );
};

const calculateBillableAmount = (timeSpent, rate, vatMultiplier) => {
    if (!timeSpent) return 0;
    if (!rate) return 0;

    return Math.max(
        0,
        new Decimal(timeSpent).div(new Decimal(60)).times(new Decimal(rate)).times(vatMultiplier).toNumber()
    );
};

const calculateRateWhenBillableAmountChanges = (timeSpent, billableAmount, vatMultiplier) => {
    if (!timeSpent) return 0;
    if (!billableAmount) return 0;

    return Math.max(
        0,
        new Decimal(billableAmount)
            .div(new Decimal(vatMultiplier).times(new Decimal(timeSpent).div(new Decimal(60))))
            .toNumber()
    );
};

const buildLineItem = (result, state) => {
    return {
        description: state.paramsUsedForSearch.groupByService
            ? `${state.organisation?.workflow_definition?.services?.[result.timeWeekEntry?.service]?.label}`
            : result.timeWeekEntry?.description,
        timeSpent: parseFloat(result.total || result.timeWeekEntry?.timeSpent),
        rate: parseFloat(result.timeWeekEntry?.billableAmount),
        billableAmount: calculateBillableAmount(
            result.total || result.timeWeekEntry?.timeSpent,
            result.timeWeekEntry?.billableAmount,
            1
        ),
        type: 'tracked_entry',
        id: result.id,
        grouped_entries: (result.groupedEntries || [])?.map((x) => x.id),
        groupedEntries: result.groupedEntries || [],
        service: result.timeWeekEntry.service,
        from: result.from,
        to: result.to,
    };
};

export default GenerateInvoice;
