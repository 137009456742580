/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import ReactSelect from 'react-select';
import { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Flex, Input, Label, Paragraph, Textarea } from 'theme-ui';
import theme from '../../theme';
import Divider from '../divider';
import ConfirmDialog from '../tasks/confirm-dialog';
import { toast } from 'react-toastify';
import ReactQuill, { Quill } from 'react-quill';
import { ImageDrop } from 'quill-image-drop-module';
import QuillResizeImage from 'quill-resize-image';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/resize', QuillResizeImage);

const ImageFormat = Quill.import('formats/image');

class CustomImage extends ImageFormat {
    static formats(domNode) {
        return {
            ...super.formats(domNode),
            style: domNode.getAttribute('style'), // Retain the style attribute
        };
    }

    format(name, value) {
        if (name === 'style') {
            if (value) {
                this.domNode.setAttribute('style', value);
            } else {
                this.domNode.removeAttribute('style');
            }
        } else {
            super.format(name, value);
        }
    }
}

// Register the Custom Image Format
Quill.register(CustomImage, true);

function filterConditionalContent(html, model) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const paragraphs = Array.from(doc.body.querySelectorAll('p'));

    let isConditionalBlock = false;
    let includeBlock = false;
    let filteredHtml = '';

    paragraphs.forEach((paragraph) => {
        const textContent = paragraph.textContent.trim();

        if (textContent.startsWith('#')) {
            // Check for condition start tag
            if (textContent.startsWith('#user.')) {
                const condition = textContent.slice(1); // Remove the # prefix
                try {
                    includeBlock = evalCondition(condition, model); // Evaluate condition
                } catch (error) {
                    console.error('Invalid condition:', condition, error);
                    includeBlock = false;
                }
                isConditionalBlock = true; // Enter conditional block
            } else if (textContent === '#end') {
                isConditionalBlock = false; // Exit conditional block
                includeBlock = false;
            }
        } else {
            console.log(isConditionalBlock, includeBlock, paragraph.outerHTML);

            // Append content only if within a conditional block and the condition is true
            if (!isConditionalBlock || includeBlock) {
                filteredHtml += paragraph.outerHTML;
            }
        }
    });

    return filteredHtml;
}

export const buildDataForReport = (client, docParams) => {
    const data = {};

    data.clientName = client.name;
    data.companyNumber = client.companyNumber;

    data.services = '';
    data.date = moment().format('Do MMMM YYYY');
    data.clientReference = client.internalReferenceCode;

    data.mainContactName = `${docParams?.primary_contact?.first_name} ${docParams?.primary_contact?.last_name}`;
    data.mainContactJobTitle = docParams?.primary_contact?.job_title;
    data.mainContactFirstName = docParams?.primary_contact?.first_name;
    data.mainContactLastName = docParams?.primary_contact?.last_name;

    data.addr = {
        ...client.companiesHouseData?.profile?.registeredOfficeAddress,
        addressLineTwo:
            client.companiesHouseData?.profile?.registeredOfficeAddress?.addressLineTwo ||
            client.companiesHouseData?.profile?.registeredOfficeAddress?.locality ||
            '',
    };

    data.mainContactAddress = {
        ...(docParams?.primary_contact?.address || {}),
    };

    return data;
};

function evalCondition(condition, model) {
    try {
        // Evaluate the condition dynamically
        const [left, operator, ...rightParts] = condition.split(/(=|!=|>|<|>=|<=)/).map((part) => part.trim());
        const right = rightParts.join(' '); // Join right side in case of multi-word strings

        const leftValue = new Function('model', `with(model) { return ${left}; }`)(model);
        let rightValue = right.replace(/^['"]|['"]$/g, ''); // Remove quotes if they exist

        if (rightValue === 'true') {
            rightValue = true;
        }
        if (rightValue === 'false') {
            rightValue = false;
        }

        switch (operator) {
            case '=':
                return leftValue == rightValue; // Loose equality
            case '!=':
                return leftValue != rightValue; // Inequality
            case '>':
                return leftValue > rightValue;
            case '<':
                return leftValue < rightValue;
            case '>=':
                return leftValue >= rightValue;
            case '<=':
                return leftValue <= rightValue;
            default:
                throw new Error(`Unsupported operator: ${operator}`);
        }
    } catch (error) {
        console.error('Error evaluating condition:', condition, error);
        return false;
    }
}

export const loadProposals = async (state, updateState, localState, updateLocalState) => {
    if (!state.clientInView.id) return;
    updateState({ ...state, loading: true });

    try {
        const [
            {
                data: { proposals },
            },
            {
                data: { proposals: templateProposals },
            },
        ] = await Promise.all([
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/proposals`, {
                params: {
                    client_id: state.clientInView.raw_id,
                },
            }),
            axios.get(`${process.env.REACT_APP_AQRU_AI_API}/template-proposals`, {
                params: {
                    client_id: state.clientInView.raw_id,
                },
            }),
        ]);

        updateState({ ...state, loading: false });

        updateLocalState({ ...localState, proposals, templateProposals });
    } catch (e) {
        updateState({ ...state, loading: false });
        updateLocalState({ ...localState, proposals: [], templateProposals: [] });
    }
};

const saveProposal = async (
    state,
    updateState,
    localState,
    updateLocalState,
    fireAndForgetSave = false,
    generatePdf
) => {
    try {
        if (!fireAndForgetSave) {
            updateState({ ...state, loading: true, error: null });
        }

        const keyValuePairs = {
            name: localState.proposal?.name,
            description: localState.proposal?.description,
            definition: localState.proposal.definition || {},
            client_id: state.clientInView?.raw_id,
        };

        const payloadStringified = JSON.stringify(keyValuePairs);

        if (fireAndForgetSave && localState.lastPayload && localState.lastPayload === payloadStringified) {
            return;
        }

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/proposals${
                localState.proposal?.id ? `/${localState.proposal?.id}` : ''
            }`,
            method: localState.proposal?.id ? 'PUT' : 'POST',
            data: keyValuePairs,
        });

        const {
            data: { proposals },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/proposals`,
            params: {
                client_id: state.clientInView.raw_id,
            },
        });

        let pdf_generated_id;
        if (generatePdf) {
            let parsedHtml = localState.proposal?.definition?.html
                .replace(/{client.name}/g, state.clientInView?.name)
                .replace(
                    /{client.services}/g,
                    '<ul>' +
                        state.clientInView?.client_data?.new_client_services_offered
                            ?.map((x) => {
                                const service = state.organisation?.workflow_definition?.services?.[x];
                                return `<li>${service?.label}</li>`;
                            })
                            .join('') +
                        '</ul>'
                )
                .replace(/{today}/g, moment().format('DD/MM/YYYY'))
                ?.replace(/<p><br><\/p>/g, '<div style="height: 10px;"></div>');

            // parsedHtml = filterConditionalContent(parsedHtml, { user: { isAdmin: true, title: 'Mr' } });

            const html = `<html>
        <head>
            <link
                href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap"
                rel="stylesheet"
            />
            <style>
                * {
                    font-family: 'Raleway', 'Inter', 'Roboto', 'Helvetica Neue', sans-serif;
                }
                thead { display: table-header-group; }
                tfoot { display: table-row-group; }
                tr, .bank_details { page-break-inside: avoid; }
                .ql-align-right {
                    text-align: right;
                }
                p {
                    line-height: 10px;
                }
            </style>
        </head>
        <body>
        
        ${parsedHtml}
        </body></html>`;

            // ${
            //     state.organisation?.default_visualisation?.logo
            //         ? `<img width="350px" style="position: absolute; right: 20px;" src="${state.organisation?.default_visualisation?.logo}" /><div style="height: 70px;"></div>`
            //         : ''
            // }

            const { data: generateResponse } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/proposals/${localState.proposal?.id || data.id}/generate`,
                method: 'POST',
                data: {
                    html,
                },
            });

            const {
                data: { url },
            } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${generateResponse.uuid}`, {});

            const { data: docResponse } = await axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer', // Ensures the response is in Blob format
            });

            const blobURL = window.URL.createObjectURL(new Blob([docResponse], { type: 'application/pdf' }));

            const newTab = window.open();
            if (newTab) {
                newTab.location.href = blobURL;
            }

            pdf_generated_id = generateResponse.uuid;
        }

        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            lastPayload: localState.proposal?.id ? payloadStringified : null,
            proposals,
            proposal: {
                ...localState.proposal,
                updated_at: moment(),
                id: localState.proposal?.id || data.id,
                ...(pdf_generated_id ? { pdf_generated_id } : {}),
            },
        });

        if (!fireAndForgetSave && !generatePdf) {
            toast('Proposal saved', { type: 'success' });
        }
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('We are currently unable to save your proposal', { type: 'error' });
    }
};

const Proposals = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ proposal: null });

    useEffect(() => {
        loadProposals(state, updateState, localState, updateLocalState);
    }, []);

    const quillRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(null);

    if (localState.proposal_view === 'generate_proposal') {
        return (
            <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20 }}>
                <Paragraph sx={{ fontWeight: 600, mt: 0, fontSize: 25 }}>
                    {localState.proposal?.id ? 'Edit proposal' : 'Add new proposal'}
                </Paragraph>

                <Divider />

                <Label sx={{ mb: 10 }}>Copy existing template</Label>

                <ReactSelect
                    aria-labelledby="service-label"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '370px',
                            minHeight: '40px',
                            borderRadius: 10,
                            border: `1px solid #a3a69b`,
                            fontSize: '14px',
                        }),
                    }}
                    onChange={(c) => {
                        console.log(c.item);

                        updateLocalState({
                            ...localState,
                            proposal: {
                                ...localState.proposal,
                                name: c.item.name,
                                description: c.item.description,
                                definition: c.item.definition,
                            },
                        });
                    }}
                    placeholder="Copy existing template"
                    options={localState.templateProposals?.map((p) => ({
                        value: p.id,
                        item: p,
                        label: p.name,
                    }))}
                />

                <Label sx={{ mt: 20 }}>Name</Label>
                <Input
                    onChange={(e) =>
                        updateLocalState({
                            ...localState,
                            proposal: {
                                ...localState.proposal,
                                name: e.target.value,
                            },
                        })
                    }
                    value={localState.proposal?.name}
                    sx={{ width: 300, height: 40, mt: 10 }}
                />

                <Label sx={{ mt: 30 }}>Description</Label>
                <Textarea
                    rows={5}
                    onChange={(e) =>
                        updateLocalState({
                            ...localState,
                            proposal: {
                                ...localState.proposal,
                                description: e.target.value,
                            },
                        })
                    }
                    value={localState.proposal?.description}
                    sx={{ width: 500, mt: 10, mb: 20 }}
                />

                <Flex sx={{ flexDirection: 'column', width: 400, mb: 40 }}>
                    <Label id="reminders-label" sx={{ mr: 30 }}>
                        Reminders
                    </Label>
                    <ReactSelect
                        aria-labelledby="reminders-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                border: `1px solid #a3a69b`,
                                fontSize: '14px',
                            }),
                        }}
                        isMulti
                        onChange={async (c) => {
                            updateLocalState({
                                ...localState,
                                proposal: {
                                    ...localState.proposal,
                                    definition: {
                                        ...(localState?.proposal?.definition || {}),
                                        reminders: c,
                                    },
                                },
                            });
                        }}
                        placeholder="Select..."
                        value={localState?.proposal?.definition?.reminders || []}
                        options={[
                            {
                                value: 1,
                                label: '1 Day after',
                            },
                            {
                                value: 2,
                                label: '2 Days after',
                            },
                            {
                                value: 3,
                                label: '3 Days after',
                            },
                            {
                                value: 4,
                                label: '4 Days after',
                            },
                            {
                                value: 5,
                                label: '5 Days after',
                            },
                            {
                                value: 6,
                                label: '6 Days after',
                            },
                            {
                                value: 7,
                                label: '1 week after',
                            },
                            {
                                value: 14,
                                label: '2 weeks after',
                            },
                        ]}
                    />
                </Flex>

                <Flex sx={{ mt: 0, flexDirection: 'column', width: 600, mb: 60 }}>
                    <Label id="task-desc" sx={{}}>
                        Template proposal letter
                    </Label>
                    <Divider />
                    <ReactSelect
                        aria-labelledby="service-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '370px',
                                minHeight: '40px',
                                borderRadius: 10,
                                border: `1px solid #a3a69b`,
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            const value = `{${c.value}}`;

                            if (quillRef.current) {
                                if (cursorPosition !== null) {
                                    const editor = quillRef.current.getEditor();
                                    if (editor) {
                                        editor.insertText(cursorPosition, value, 'user');
                                        setCursorPosition(cursorPosition + value.length);
                                    }
                                } else {
                                    const editor = quillRef.current.getEditor();
                                    if (editor) {
                                        editor.insertText(0, value, 'user');
                                        setCursorPosition(0);
                                    }
                                }
                            }
                        }}
                        placeholder="Select a dynamic token for your letter"
                        options={[
                            { value: 'client.name', label: 'Client name' },
                            { value: 'client.services', label: 'Client services' },
                            { value: 'today', label: 'Todays Date' },
                        ]}
                    />
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        modules={{
                            toolbar: {
                                container: [
                                    [{ header: '1' }, { header: '2' }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ align: [] }],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['image'],
                                ],
                            },
                            imageDrop: true,
                            resize: {
                                locale: {},
                            },
                        }}
                        formats={[
                            'image',
                            'align',
                            'style',
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'list',
                            'bullet',
                            'link',
                        ]}
                        value={localState.proposal?.definition?.html}
                        style={{ height: 400, marginTop: 20 }}
                        onChange={(val, delta, source, editor) => {
                            if (source === 'api') return;

                            updateLocalState({
                                ...localState,
                                proposal: {
                                    ...localState.proposal,
                                    definition: {
                                        ...(localState.proposal.definition || {}),
                                        html: editor.getHTML(),
                                    },
                                },
                            });

                            if (quillRef) {
                                const editor = quillRef.current?.getEditor();
                                if (editor) {
                                    const currentCursorPosition = editor.getSelection()?.index || 0;
                                    setCursorPosition(currentCursorPosition);
                                }
                            }
                        }}
                    />

                    <div id="hidden_html_content" />
                </Flex>

                <Flex
                    sx={{
                        position: 'fixed',
                        height: 80,
                        background: 'white',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        alignItems: 'center',
                        borderTop: '2px solid #EFEFEF',
                        width: '100%',
                        zIndex: 998,
                    }}
                >
                    <Button
                        variant="light"
                        sx={{ width: 120, ml: 150 }}
                        onClick={async () => {
                            const {
                                data: { proposals },
                            } = await axios({
                                url: `${process.env.REACT_APP_AQRU_AI_API}/proposals`,
                                params: { client_id: state.clientInView?.raw_id },
                            });
                            updateLocalState({
                                ...localState,
                                proposal: {},
                                proposals,
                                proposal_view: 'list',
                            });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                    >
                        <i
                            style={{
                                fontSize: '12px',
                                cursor: 'pointer',
                                marginRight: '7px',
                            }}
                            className="fal fa-chevron-left"
                            aria-hidden="true"
                        />
                        Back
                    </Button>
                    <Button
                        sx={{ ml: 20, width: 120, height: 40, fontSize: 14 }}
                        onClick={async () => {
                            await saveProposal(state, updateState, localState, updateLocalState);
                        }}
                    >
                        <i
                            style={{
                                fontSize: '12px',
                                cursor: 'pointer',
                                marginRight: '7px',
                            }}
                            className="fal fa-save"
                            aria-hidden="true"
                        />
                        Save
                    </Button>
                    <Button
                        sx={{ ml: 20, width: 120, height: 40, fontSize: 14 }}
                        variant="light"
                        onClick={async () => {
                            await saveProposal(state, updateState, localState, updateLocalState, false, true);
                        }}
                    >
                        <i
                            style={{
                                fontSize: '12px',
                                cursor: 'pointer',
                                marginRight: '7px',
                            }}
                            className="fas fa-file-pdf"
                            aria-hidden="true"
                        />
                        Preview
                    </Button>
                    {localState.proposal.pdf_generated_id ? (
                        <Flex>
                            <Button
                                sx={{ ml: 20, width: 120, height: 40, fontSize: 14 }}
                                variant="light"
                                onClick={async () => {
                                    const {
                                        data: { url },
                                    } = await axios.get(
                                        `${process.env.REACT_APP_AQRU_AI_API}/documents/${localState.proposal.pdf_generated_id}`,
                                        {}
                                    );

                                    const a = window.document.createElement('a');

                                    a.href = url;
                                    a.target = '_blank';
                                    a.download = 'Proposal.pdf';

                                    a.click();
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        marginRight: '7px',
                                    }}
                                    className="fas fa-download"
                                    aria-hidden="true"
                                />
                                Download
                            </Button>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`/clients?id=${state.clientInView?.id}&tab=doc_sign&doc_to_open=${localState.proposal.pdf_generated_id}&proposal_id=${localState.proposal?.id}`}
                            >
                                <Button sx={{ ml: 20, width: 190, height: 40, fontSize: 14 }} variant="light">
                                    <i
                                        style={{
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            marginRight: '7px',
                                        }}
                                        className="fas fa-folder-open"
                                        aria-hidden="true"
                                    />
                                    Open in Doc Sign
                                </Button>
                            </a>
                        </Flex>
                    ) : null}
                    {localState.proposal?.updated_at ? (
                        <Paragraph sx={{ ml: 30 }}>
                            Last saved: {moment(localState.proposal?.updated_at).format('HH:mm DD/MM/YYYY')}
                        </Paragraph>
                    ) : null}
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20 }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20 }}>Proposals</Paragraph>
            <Divider width={'95%'} />
            <Button
                onClick={() =>
                    updateLocalState({
                        ...localState,
                        proposal_view: 'generate_proposal',
                        proposal: {},
                        error: null,
                    })
                }
                sx={{ width: 100, height: 40, fontSize: 14 }}
            >
                <i
                    style={{
                        marginRight: '7px',
                    }}
                    className={`fas fa-plus-circle`}
                />
                New
            </Button>

            {state.showDeleteProposalConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteProposalConfirmation"
                    text="Are you sure you wish to delete this proposal?"
                />
            )}

            <table
                style={{
                    borderCollapse: 'collapse',
                    width: '1000px',
                    marginTop: 20,
                    marginRight: 20,
                    fontSize: 14,
                }}
            >
                <thead
                    style={{
                        backgroundColor: theme.colors.primary,
                        opacity: 0.9,
                        height: 50,
                        color: 'white',
                    }}
                >
                    <tr style={{ borderTop: '0px solid lightGrey' }}>
                        <th style={{ width: 150, textAlign: 'center', paddingLeft: 20 }}>Name</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Description</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Status</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Created At</th>
                        <th style={{ width: 125 }}></th>
                    </tr>
                </thead>
                <tbody style={{ borderCollapse: 'collapse' }}>
                    {localState?.proposals?.map((proposal, idx) => (
                        <tr key={`invoice_${idx}`}>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {proposal.name}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {proposal.description}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {proposal.status}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {moment(proposal.created_at).format('HH:mm DD/MM/YYYY')}
                            </td>
                            <td style={{ padding: 25, width: 125 }}>
                                <i
                                    style={{
                                        marginLeft: 20,
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        color: 'orange',
                                    }}
                                    className="fas fa-pencil"
                                    aria-hidden="true"
                                    onClick={async () => {
                                        updateLocalState({
                                            ...localState,
                                            proposal_view: 'generate_proposal',
                                            error: null,
                                            proposal,
                                        });
                                    }}
                                />
                                <i
                                    style={{
                                        marginLeft: 20,
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        color: 'green',
                                    }}
                                    className="fas fa-download"
                                    aria-hidden="true"
                                    onClick={async () => {
                                        const {
                                            data: { url },
                                        } = await axios.get(
                                            `${process.env.REACT_APP_AQRU_AI_API}/documents/${proposal.pdf_generated_id}`,
                                            {}
                                        );

                                        const a = window.document.createElement('a');

                                        a.href = url;
                                        a.target = '_blank';
                                        a.download = 'Proposal.pdf';

                                        a.click();
                                    }}
                                />
                                <i
                                    style={{
                                        marginLeft: 20,
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        color: 'red',
                                    }}
                                    className="fal fa-trash"
                                    aria-hidden="true"
                                    onClick={async () => {
                                        updateState({ ...state, loading: true });
                                        await axios.delete(
                                            `${process.env.REACT_APP_AQRU_AI_API}/proposals/${proposal.id}`,
                                            {}
                                        );

                                        const {
                                            data: { proposals },
                                        } = await axios.get(
                                            `${process.env.REACT_APP_AQRU_AI_API}/proposals?client_id=${state.clientInView?.raw_id}`,
                                            {}
                                        );

                                        updateLocalState({ ...localState, proposals });
                                        updateState({ ...state, loading: false });
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Flex>
    );
};

export default Proposals;
