/* eslint-disable no-undef */
import AsyncSelect from 'react-select/async';
import { Button, Flex, Input, Label, Paragraph } from 'theme-ui';
import { loadClientsAsync, loadTasksAsync } from '../tasks/filters';
import ReactQuill from 'react-quill';
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import moment from 'moment';
import axios from 'axios';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { emailBodyDefaultState, loadMessages } from '../../pages/inbox';

const improveEmail = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const { data: aiResponse } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/ai/query`,
            method: 'POST',
            data: {
                messages: [
                    {
                        role: 'assistant',
                        content: `
                    Please improve the following email content that a client will send to his client:

                    ${state.newEmailBody}

                    Do not include a subject and return it as valid html with nice line spacing wrapping text with p tags
                    Do not prefix response with html and include strange \`\`\` characters
                    Between separate parapraphs insert suitable line breaks tags <br/> to space the email out nicely for presentation
                    `,
                    },
                ],
            },
        });

        const newState = {
            ...state,
            newEmailBody: aiResponse.data?.choices[0]?.message?.content.replace('```json', '').replace('```', ''),
            loading: false,
        };

        updateState(newState);
    } catch (e) {
        console.log(e);

        updateState({ ...state, loading: false });
        toast(e?.response?.data?.message || 'Uh oh, there has been an issue, please try again or contact support', {
            type: 'error',
        });
    }
};

const replaceTokensInTemplate = (template, client, task) => {
    const todaysDate = moment().format('DD/MM/YYYY');

    const primaryContact = client?.client_data?.key_contacts?.find((contact) => contact.is_primary_contact) || {};

    const tokenValues = {
        '{todaysDate}': todaysDate,
        '{client.name}': client?.name,
        '{client.company_number}': client?.company_number,
        '{client.primary_contact.first_name}': primaryContact.first_name || '',
        '{client.primary_contact.last_name}': primaryContact.last_name || '',
        '{task.relevant_period_end}': task ? moment(task.additional_data.relevant_period_end).format('DD/MM/YYYY') : '',
    };

    let formattedString = template;

    Object.keys(tokenValues).forEach((token) => {
        if (tokenValues[token]) {
            formattedString = formattedString.replace(new RegExp(token, 'g'), tokenValues[token]);
        }
    });

    return formattedString;
};

const sendEmail = async (state, updateState, saveAsDraft = false) => {
    try {
        if (!saveAsDraft) {
            if (!state.newEmailSubject || !state.newEmailBody || !state.newEmailTo) return;
        }

        updateState({ ...state, loading: true });

        const attachments = [];

        for (let index = 0; index < state.newEmailFiles.length; index++) {
            const newFile = state.newEmailFiles[index];

            const {
                data: { document_uuid, presigned_url },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/documents`,
                method: 'POST',
                data: {
                    content_type: newFile.type,
                    file_name: newFile.name,
                },
            });

            await axios.put(presigned_url, newFile, {
                headers: { 'Content-Type': newFile.type },
            });

            attachments.push({ uuid: document_uuid, fileName: newFile.name });
        }

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/outlook/emails`,
            method: 'POST',
            data: {
                save_draft: saveAsDraft,
                draft_id: state.newEmailDraftId,
                client_id: state.newEmailClient?.value,
                task_id: state.newEmailTask?.value,
                type: state.replyType === 'forward' ? 'forward' : 'reply',
                message_id_of_reply: state.replyType ? state.emails?.[state.selectedEmailIndex]?.id : null,
                attachments,
                email_data: {
                    ...(state.replyType
                        ? {
                              comment:
                                  `<html><body>` + state.newEmailBody?.replace(/<p><br><\/p>/g, '') + '</body></html>',
                          }
                        : {}),
                    message: {
                        subject: state.newEmailSubject,
                        ...(state.replyType
                            ? {}
                            : {
                                  body: {
                                      contentType: 'HTML',
                                      content:
                                          `<html><body>` +
                                          state.newEmailBody?.replace(/<p><br><\/p>/g, '') +
                                          '</body></html>',
                                  },
                              }),
                        toRecipients:
                            state.newEmailTo?.map((x) => {
                                return {
                                    emailAddress: {
                                        address: x?.value,
                                    },
                                };
                            }) || [],
                        ccRecipients:
                            state.newEmailCc?.map((x) => {
                                return {
                                    emailAddress: {
                                        address: x?.value,
                                    },
                                };
                            }) || [],
                        bccRecipients:
                            state.newEmailBcc?.map((x) => {
                                return {
                                    emailAddress: {
                                        address: x?.value,
                                    },
                                };
                            }) || [],
                    },
                    ...(saveAsDraft ? {} : { saveToSentItems: true }),
                },
            },
        });

        if (saveAsDraft) {
            toast('Email saved', { type: 'success' });
        } else {
            toast('Email sent', { type: 'success' });
        }

        await loadMessages(
            {
                ...state,
                loading: false,
                view: 'read',
                type: 'inbox',
                ...emailBodyDefaultState(),
            },
            updateState,
            true
        );
    } catch (ex) {
        console.log(ex);

        updateState({ ...state, loading: false });
        toast(`There has been an error ${saveAsDraft ? 'saving' : 'sending'} your email, please try again later`, {
            type: 'error',
        });
    }
};

const Compose = ({ state, updateState }) => {
    const fileInputRef = useRef();

    // Handle file selection
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            updateState({ ...state, newEmailFiles: [...(state.newEmailFiles || []), ...files] });
        }
    };

    // Trigger file input click
    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                mb: 200,
            }}
        >
            <Flex sx={{}}>
                <Flex sx={{ flexDirection: 'column', mt: 0 }}>
                    <Label sx={{ mt: 0 }} id="client-label">
                        Client
                    </Label>
                    <Flex>
                        <AsyncSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            aria-labelledby="client-label"
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    newEmailClient: c,
                                    newEmailBody: replaceTokensInTemplate(
                                        state.newEmailBody,
                                        c?.fullData,
                                        state.newEmailTask?.fullData
                                    ),
                                });
                            }}
                            placeholder="Select client"
                            value={state.newEmailClient}
                            loadOptions={loadClientsAsync(state, updateState)}
                        />
                        {state.newEmailClient ? (
                            <a href={`/clients?id=${state.newEmailClient.uuid}`} target="_blank" rel="noreferrer">
                                <Button sx={{ ml: 10, mt: 10 }}>
                                    <i
                                        style={{
                                            marginRight: '7px',
                                        }}
                                        className={`fas fa-external-link-alt`}
                                    />
                                    Open
                                </Button>
                            </a>
                        ) : null}
                    </Flex>
                </Flex>

                <Flex sx={{ flexDirection: 'column', ml: 10 }}>
                    <Label sx={{}} id="task-label">
                        Task (Optional)
                    </Label>
                    <Flex>
                        <AsyncSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            aria-labelledby="task-label"
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    newEmailTask: c,
                                    newEmailBody: replaceTokensInTemplate(
                                        state.newEmailBody,
                                        state.newEmailClient?.fullData,
                                        c?.fullData
                                    ),
                                });
                            }}
                            placeholder="Select task"
                            value={state.newEmailTask}
                            defaultOptions={state.defaultTasksForDropDown || []}
                            loadOptions={loadTasksAsync(
                                state,
                                updateState,
                                state.newEmailClient?.id,
                                'order_by_created_at'
                            )}
                        />
                        {state.newEmailTask ? (
                            <a href={`/tasks?id=${state.newEmailTask.uuid}`} target="_blank" rel="noreferrer">
                                <Button sx={{ ml: 10, mt: 10 }}>
                                    <i
                                        style={{
                                            marginRight: '7px',
                                        }}
                                        className={`fas fa-external-link-alt`}
                                    />
                                    Open
                                </Button>
                            </a>
                        ) : null}
                    </Flex>
                </Flex>
            </Flex>

            <Paragraph sx={{ mb: 10 }}>To</Paragraph>
            <Flex sx={{ alignItems: 'center' }}>
                <CreatableSelect
                    isClearable
                    options={state.newEmailClient?.fullData?.client_data?.key_contacts?.map((y) => ({
                        value: y.email_address,
                        label: `${y.first_name} ${y.last_name}`,
                    }))}
                    isMulti
                    value={state.newEmailTo}
                    onChange={(c) => updateState({ ...state, newEmailTo: c })}
                    placeholder="Select or type in a contact email address"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '500px',
                            minHeight: '40px',
                            borderRadius: 10,
                            marginBottom: '10px',
                            border: '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                />
                <Paragraph
                    onClick={() => updateState({ ...state, showCc: true })}
                    sx={{ mt: '-5px', ml: 20, color: 'blue', cursor: 'pointer' }}
                >
                    Cc
                </Paragraph>
                <Paragraph
                    onClick={() => updateState({ ...state, showBcc: true })}
                    sx={{ mt: '-5px', ml: 20, color: 'blue', cursor: 'pointer' }}
                >
                    Bcc
                </Paragraph>
            </Flex>
            {state.showCc ? (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: 10 }}>Cc</Paragraph>
                    <CreatableSelect
                        isClearable
                        options={[
                            ...(state.newEmailClient?.fullData?.client_data?.key_contacts?.map((y) => ({
                                value: y.email_address,
                                label: `${y.first_name} ${y.last_name}`,
                            })) || []),
                            ...(state.newEmailClient?.fullData?.relations?.map((y) => ({
                                value: y.email_address,
                                label: y.name,
                            })) || []),
                        ]}
                        value={state.newEmailCc}
                        isMulti
                        onChange={(c) => updateState({ ...state, newEmailCc: c })}
                        placeholder="Select or type in a contact email address"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '500px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                    />
                </Flex>
            ) : null}

            {state.showBcc ? (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: 10 }}>Bcc</Paragraph>
                    <CreatableSelect
                        isClearable
                        options={[
                            ...(state.newEmailClient?.fullData?.client_data?.key_contacts?.map((y) => ({
                                value: y.email_address,
                                label: `${y.first_name} ${y.last_name}`,
                            })) || []),
                            ...(state.newEmailClient?.fullData?.relations?.map((y) => ({
                                value: y.email_address,
                                label: y.name,
                            })) || []),
                        ]}
                        value={state.newEmailBcc}
                        isMulti
                        onChange={(c) => updateState({ ...state, newEmailBcc: c })}
                        placeholder="Select or type in a contact email address"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '500px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                    />
                </Flex>
            ) : null}

            <Paragraph sx={{ mb: 10 }}>Subject</Paragraph>
            <Input
                onChange={(e) => updateState({ ...state, newEmailSubject: e.target.value })}
                value={state.newEmailSubject}
                sx={{ mb: 10, width: 500 }}
            />

            <Button variant="light" sx={{ width: 150, mt: 10, mb: 10 }} onClick={handleClick}>
                Attach files
            </Button>
            <input
                type="file"
                ref={fileInputRef}
                multiple
                style={{ display: 'none' }} // Hide the default file input
                onChange={handleFileSelect}
            />
            {state.newEmailFiles ? (
                <Flex sx={{ mb: 10, width: 800, flexWrap: 'wrap' }}>
                    {state.newEmailFiles?.map((file, fileIdx) => (
                        <Flex sx={{ alignItems: 'center', mr: 10 }} key={`file_${fileIdx}`}>
                            <Paragraph>{file.name}</Paragraph>
                            <i
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '8px',
                                    color: 'red',
                                }}
                                className="fal fa-trash"
                                aria-hidden="true"
                                onClick={() => {
                                    updateState({
                                        ...state,
                                        newEmailFiles: state.newEmailFiles?.filter((f, fIx) => {
                                            return fIx !== fileIdx;
                                        }),
                                    });
                                }}
                            />
                        </Flex>
                    ))}
                </Flex>
            ) : null}
            {state.replyType ? null : (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: 10 }}>Copy from Template</Paragraph>
                    <Flex>
                        <ReactSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '500px',
                                    minHeight: '40px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={(c) =>
                                updateState({
                                    ...state,
                                    newEmailBody: replaceTokensInTemplate(
                                        c.content,
                                        state.newEmailClient?.fullData,
                                        state.newEmailTask?.fullData
                                    ),
                                })
                            }
                            placeholder={'Copy email template'}
                            value={state?.emailTemplate}
                            options={
                                state.templates?.map((x) => ({
                                    value: x.id,
                                    content: x.content,
                                    label: x.name,
                                })) || []
                            }
                        />
                        {state.newEmailBody ? (
                            <Button
                                sx={{ ml: 140 }}
                                onClick={async () => {
                                    await improveEmail(state, updateState);
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                    }}
                                    className="fas fa-sync"
                                    aria-hidden="true"
                                />
                                Improve with AI
                            </Button>
                        ) : null}
                    </Flex>
                </Flex>
            )}

            <ReactQuill
                theme="snow"
                value={state.newEmailBody}
                style={{
                    marginTop: 10,
                    height: 400,
                    width: 800,
                    marginBottom: state.newEmailBodyShowingPreviousChain ? 40 : 200,
                }}
                onChange={(val) =>
                    updateState({
                        ...state,
                        loading: false,
                        newEmailBody: val,
                    })
                }
            />

            {state.newEmailBodyShowingPreviousChain ? (
                <Flex
                    dangerouslySetInnerHTML={{
                        __html: state.newEmailBodyShowingPreviousChain,
                    }}
                    sx={{
                        flexDirection: 'column',
                        width: 800,
                        border: '1px solid #ccc',
                        borderTop: 0,
                        padding: 10,
                        fontSize: 14,
                        mb: 100,
                    }}
                />
            ) : null}

            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                {state.replyType ? null : (
                    <Button
                        onClick={async () => {
                            await sendEmail(state, updateState, true);
                        }}
                        variant="light"
                        sx={{ ml: 20, mr: 10, width: 150 }}
                    >
                        <i
                            style={{
                                marginRight: '7px',
                            }}
                            className={`fal fa-save`}
                        />
                        Save
                    </Button>
                )}
                <Button
                    sx={{ ml: state.replyType ? 20 : 0, width: 150 }}
                    onClick={async () => {
                        await sendEmail(state, updateState);
                    }}
                >
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-paper-plane`}
                    />
                    Send
                </Button>
            </Flex>
        </Flex>
    );
};

export default Compose;
